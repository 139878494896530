import React, { useContext, useEffect, useState, useCallback } from 'react';
import { WalletContext } from '../WalletContext';
import GaugeComponent from 'react-gauge-component';
import MilestonesRewardsCards from './MilestonesRewardsCards';
import logo from '../assets/MaleLogo.png';
import Lovelace from '../assets/mileston/lovelace.png';
import Video from '../assets/membervideos/GoalSetting.mp4';
import DashboardOverview from '../assets/tutorial/dashboard-overview.mp4';
import { useAccount, useReadContract, useReadContracts } from 'wagmi';
import '../styles/ReferralNode.css';

const ReferralNode = ({ account, level = 0, onExpand, expandedNodes, currentPath }) => {
  const { address, isConnected } = useAccount();
  const [referrals, setReferrals] = useState([]);
  const [totalEarned, setTotalEarned] = useState(0);
  const { contract, timeDateContract, joiningDate, setJoiningDate, usdtDecimal, stakingContract } = useContext(WalletContext);
  
  const { data: milestonAmount } = useReadContract({
    ...contract,
    functionName: 'getMilestonAmount',
    args: [address],
    enabled: Boolean(address)
  });

  const expanded = expandedNodes.has(account);

  const { data: getTaxsNumbers } = useReadContract({
    ...timeDateContract,
    functionName: 'getTaxNumbers',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: referralAddresses } = useReadContract({
    ...contract,
    functionName: 'getReferrals',
    args: [account],
    enabled: Boolean(account)
  });

  const { data: _totalEarned } = useReadContract({
    ...contract,
    functionName: 'getTotalEarning',
    args: [account],
    enabled: Boolean(account)
  });

  const { data: _stakingTime } = useReadContract({
    ...stakingContract,
    functionName: 'stakingTime',
    args: [address],
    enabled: Boolean(address)
  });

  const updateData = useCallback(async () => {
    if (!timeDateContract || !address) {
      console.log('StakingContract or address not available');
      return;
    }
    try {
      if (getTaxsNumbers) {
        console.log('Tax Data', getTaxsNumbers);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  }, [timeDateContract, address, getTaxsNumbers]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  useEffect(() => {
    if (expanded) {
      fetchReferralData();
    }
  }, [expanded, account, contract, _totalEarned, _stakingTime, referralAddresses]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  };

  const fetchReferralData = async () => {
    try {
      // Check if _stakingTime exists before using it
      if (_stakingTime) {
        const stakingTime = Number(_stakingTime.toString());
        const formattedDate = formatDate(stakingTime);
        setJoiningDate(formattedDate);
        console.log('Joining date:', formattedDate);
      }

      // Check if _totalEarned exists before using it
      const earnedTotal = _totalEarned ? Number(_totalEarned.toString()) / (10 ** usdtDecimal) : 0;

      // Check if referralAddresses exists before setting state
      if (referralAddresses) {
        setReferrals(referralAddresses);
      }

      setTotalEarned(earnedTotal.toFixed(2));
    } catch (error) {
      console.error('Error fetching referral data:', error);
      // Set default values in case of error
      setReferrals([]);
      setTotalEarned('0.00');
    }
  };

  const handleExpand = () => {
    onExpand(account);
  };

  const truncateAddress = (addr) => {
    if (typeof addr !== 'string') return 'Invalid Address';
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
  };

  const isInPath = currentPath.includes(account);

  return (
    <div>
    <div className={`referral-node level-${level}`}>
      <div className="node-content" onClick={handleExpand}>
        <img src={logo} alt="Referee Logo" className="avatar" />
        <div className="node-details">
          <div className="address">{truncateAddress(account)}</div>
          <div>Total Earned: ${totalEarned}</div>
        </div>
      </div>
      {(expanded || isInPath) && referrals.length > 0 && (
        <div className="children">
          {referrals.map((referralAddress, index) => (
            <ReferralNode
              key={index}
              account={referralAddress}
              level={level + 1}
              onExpand={onExpand}
              expandedNodes={expandedNodes}
              currentPath={currentPath}
            />
          ))}
        </div>
      )}
    </div>
    </div>
  );
};

const ReferralTree = () => {
  const { address, isConnected } = useAccount();
  const { contract } = useContext(WalletContext);
  const [userReferrals, setUserReferrals] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [currentPath, setCurrentPath] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const VideoPopup = ({ video, onCloseVideo }) => {
    return (
      <div className="video-popup-overlay">
        <div className="video-popup">
          <button className="popup-close-btn" onClick={onCloseVideo}>✕</button>
          <video className='popup-video-bacground' src={video} controls autoPlay width="100%">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  };

  const { data: milestonAmount } = useReadContract({
    ...contract,
    functionName: 'getMilestonAmount',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: refereeAddresses } = useReadContract({
    ...contract,
    functionName: 'getReferrals',
    args: [address],
    enabled: Boolean(address && isConnected)
  });

  const getPercentage = (totalAmount, amount) => {
    if (totalAmount > 0 && amount > 0) {
      return (100 / totalAmount) * amount;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    if (isConnected && refereeAddresses) {
      fetchUserReferrals();
    }
  }, [isConnected, refereeAddresses]);

  const fetchUserReferrals = async () => {
    if (!isConnected || !refereeAddresses) {
      setIsLoading(false);
      return;
    }
    try {
      setIsLoading(true);
      console.log('Fetch user referrals', refereeAddresses);
      setUserReferrals(refereeAddresses);
    } catch (error) {
      console.error('Error fetching user referrals:', error);
      setUserReferrals([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExpand = (account) => {
    if (currentPath.length === 0) {
      setCurrentPath([account]);
      setExpandedNodes(new Set([account]));
    } else {
      setCurrentPath((prevPath) => [...prevPath, account]);
      setExpandedNodes((prevExpanded) => new Set([...prevExpanded, account]));
    }
  };

  const handleBack = () => {
    setCurrentPath([]);
    setExpandedNodes(new Set());
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`${currentPath.length > 0 ? 'referral-tree-container' : 'referral-tree-container2'}`}>
      {currentPath.length > 0 && (
        <button className="back-button" onClick={handleBack}>
          Back To Home
        </button>
      )}
      {userReferrals.map((account, index) => (
        <ReferralNode
          key={index}
          account={account}
          onExpand={handleExpand}
          expandedNodes={expandedNodes}
          currentPath={currentPath}
        />
      ))}
    </div>
  );
};

export default ReferralTree;