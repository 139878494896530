import React, { useState, useEffect, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import { Gift, X } from 'lucide-react';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import '../styles/GiftBoxPopup.css';

const GiftBoxPopup = ({ isOpen, onClose }) => {
  const { stakingContract } = useContext(WalletContext);
  const [isAnimating, setIsAnimating] = useState(false);
  const [explorerLink, setExplorerLink] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [willCheck, setWillCheck] = useState(false);

  const {
    writeContract: writeWithdraw,
    data: withdrawData,
    error: withdrawError,
    isPending: isWithdrawPending
  } = useWriteContract();

  const {
    isLoading: isWithdrawLoading,
    isSuccess: isWithdrawSuccess,
    error: withdrawReceiptError,
  } = useWaitForTransactionReceipt({ hash: withdrawData });

  const showCustomAlert = (message, explorerLink = '') => {
    setAlertMessage(message);
    setExplorerLink(explorerLink);
    setShowAlert(true);
  };

  const closeCustomAlert = () => {
    setShowAlert(false);
  };

  const handleTransactionStart = () => {
    setWillCheck(true);
    console.log('Transaction started..');
  };

  // Helper function to extract error message
  const getErrorMessage = (error) => {
    if (!error) return 'Unknown error occurred';

    // Check for user rejection message in different parts of the error object
    const errorDetails = error.details || '';
    const errorMessage = error.message || '';
    const shortMessage = error.shortMessage || '';

    // Common user rejection messages
    if (errorDetails.includes('User denied transaction signature') ||
      errorMessage.includes('User denied transaction signature') ||
      shortMessage.includes('User denied transaction signature')) {
      return 'Transaction was rejected by user';
    }

    // Check for specific error types
    if (error.name === 'ContractFunctionExecutionError') {
      // Extract the Details field from the error
      const detailsMatch = error.message.match(/Details: (.*?)(?=\n|$)/);
      if (detailsMatch) {
        return detailsMatch[1];
      }
    }

    // Gas related errors
    if (errorMessage.includes('insufficient funds')) {
      return 'Insufficient funds for gas fee';
    }

    // Network related errors
    if (errorMessage.includes('network') || errorMessage.includes('disconnected')) {
      return 'Network connection error. Please check your connection';
    }

    // Contract related errors
    if (errorMessage.includes('execution reverted')) {
      const revertMatch = errorMessage.match(/execution reverted:(.*?)(?=\n|$)/);
      if (revertMatch) {
        return revertMatch[1].trim();
      }
      return 'Transaction failed during contract execution';
    }

    // If no specific error is found, return the original message or a default
    return error.message || 'Transaction failed. Please try again';
  };

  // Update the handleTransactionEnd to use the new error handling
  const handleTransactionEnd = (text, transactionHash, error) => {
    if (error) {
      const errorMessage = getErrorMessage(error);
      showCustomAlert(errorMessage);
      setWillCheck(false);
      return;
    }

    if (text && transactionHash) {
      const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
      showCustomAlert(`${text}`, explorerLink);
    } else if (text) {
      showCustomAlert(text);
    }
    setWillCheck(false);
  };

  const handleClaim = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 1000);
    try {
      console.log("Going to withdraw gif NRG..");
      handleTransactionStart();
      writeWithdraw({
        ...stakingContract,
        functionName: 'claimBusnessReward'
      });
    } catch (err) {
      console.error("Error when claim", err);
      handleTransactionEnd(null, null, err);
    }
  };

  // Effects
  useEffect(() => {
    if (willCheck) {
      if (withdrawError || withdrawReceiptError) {
        handleTransactionEnd(null, null, withdrawError || withdrawReceiptError);
      }

      if (isWithdrawSuccess) {
        handleTransactionEnd('Token withdraw successfully!');
      }
    }
  }, [willCheck, isWithdrawSuccess, withdrawError, withdrawReceiptError]);

  const isLoading = isWithdrawLoading || isWithdrawPending;

  if (!isOpen) return null;

  return (
    <div className='dialog dialog-open'>
      <div className="dialog-content" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="close-button">
          <X className="h-4 w-4" />
        </button>
        <div className="gift-container">
          <div className={`transform transition-transform duration-500 ${isAnimating ? 'scale-110' : 'scale-100'}`}>
            <div className={`mb-4 transition-all duration-500 ${isAnimating ? 'rotate-12' : ''}`}>
              <Gift className="gift-icon h-32 w-32" />
            </div>
          </div>
          <h2 className="mb-2 text-xl font-bold">You've Got a Gift! 🎉</h2>
          <p className="mb-4 text-gray-600">
            Open your special surprise and claim your reward!
          </p>
          <button
            onClick={handleClaim}
            className={`claim-button ${isAnimating ? 'scale-95' : 'scale-100'}`}
          >
            Claim Now
          </button>
        </div>
      </div>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          explorerLink={explorerLink}
          onClose={closeCustomAlert}
        />
      )}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner">
            <img src={loadingSpinner} alt="Loading..." />
          </div>
        </div>
      )}
    </div>
  );
};

export default GiftBoxPopup;