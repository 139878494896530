import React, { useState, useContext, useEffect, useCallback } from 'react';
import CustomAlert from './CustomAlert';
import { useAccount } from 'wagmi';
import { Upload, FileX, Check, Loader2, Info } from 'lucide-react';
import UserLogo from "../assets/MaleLogo.png";
import '../styles/UserProfile.css';

const UserProfile = ({ isOpen, onClose }) => {
    const { address, isConnected } = useAccount();
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [fileUrl, setFileUrl] = useState('');
    const [name, setName] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const allowedTypes = {
        'Images': '.jpg, .jpeg, .png'
    };

    const showCustomAlert = (message) => {
        setAlertMessage(message);
        if (message) {
            setShowAlert(true);
        }
    };

    const closeCustomAlert = () => {
        setShowAlert(false);
        setAlertMessage(null);
    };

    const handleUserNameInput = (inputValue) => {
        // console.log('Input value:', inputValue);
        if (inputValue.length > 8) {
            showCustomAlert('Username must be 5 characters or less');
        } else {
            setName(inputValue);
        }
    };

    const getUserData = async (ethAddress) => {
        try {
          const response = await fetch(`https://impactxtech.com/flaskapp/data/${ethAddress}`);
          const data = await response.json();
          if (response.ok) {
            setName(data.name);
            console.log('Fetched data:', data);
            // Handle the fetched data as needed
          } else {
            console.error(data.error || 'Failed to fetch data');
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      };

      useEffect(() => {
        if (isConnected && address) {
            getUserData(address);
        }
      }, [isConnected, address])

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            console.log('Selected file:', selectedFile);
            const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
            const allAllowedExtensions = Object.values(allowedTypes)
                .join(', ')
                .replace(/\./g, '')
                .split(', ');

            if (allAllowedExtensions.includes(fileExtension)) {
                setFile(selectedFile);
                setUploadStatus(null);
                setFileUrl('');
            } else {
                setFile(null);
                showCustomAlert('Selected file type is not allowed');
                e.target.value = '';
            }
        }
    };

    const uploadFile = async () => {
        if (!file) {
            showCustomAlert('Please select a profile image.');
            return;
        }
        if (!name) {
            showCustomAlert('Please provide user name.');
            return;
        }
        if (!address) {
            showCustomAlert('Please provide address.');
            return;
        }
        if (!isConnected) {
            showCustomAlert('Please connect wallet.');
            return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        formData.append('eth_address', address);
    
        console.log('Uploading file:', file.name);
        setUploading(true);
    
        try {
            const response = await fetch('https://impactxtech.com/uploads/upload', {  // Ensure the correct URL
                method: 'POST',
                body: formData,
            });
    
            console.log('Response status:', response.status);
    
            // Check if the response is JSON
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.indexOf('application/json') !== -1) {
                const data = await response.json();
                console.log('Response data:', data);
    
                if (response.ok) {
                    setUploadStatus('success');
                    setFileUrl(data.fileUrl);
                } else {
                    setUploadStatus('error');
                    showCustomAlert(data.error || 'Upload failed');
                }
            } else {
                // Handle case where response is not JSON
                showCustomAlert('Server returned a non-JSON response.');
                setUploadStatus('error');
                console.log('Non-JSON response:', await response.text());
            }
        } catch (error) {
            console.error('Upload error:', error);
            setUploadStatus('error');
            showCustomAlert('Network error: Could not connect to server');
        }
        setUploading(false);
    };
    
    // Main Render
    if (!isOpen) return null;

    return (
        <div className="profile-container">
            <div className='upload-container'>
                <div className='lavel-cross'>
                    <span>Update Profile</span>
                    <span className="close-modul-button" onClick={onClose}>X</span>
                </div>
                <div className='upload-button-area'>
                    {/* Upload Area */}
                    <div className="upload-area">
                        <Upload className="upload-icon" />
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="file-input"
                            id="file-upload"
                            accept=".jpg,.jpeg,.png"
                        />
                        <label htmlFor="file-upload" className="select-button">
                            Select File
                        </label>
                        {file && (
                            <div className="selected-file">
                                Selected: {file.name}
                            </div>
                        )}
                    </div>

                    <div className='label-input'>
                        <label className='user-name'>User name</label>
                        <input
                            className='user-name-input'
                            type="text"
                            placeholder=" Enter user name"
                            value={name}
                            onChange={(e) => handleUserNameInput(e.target.value)}
                        />
                    </div>

                    {/* Upload Button */}
                    <button onClick={uploadFile} className="upload-button">
                        <Upload className="button-icon" />
                        Update Profile
                    </button>

                    {/* Upload Progress */}
                    {uploading && (
                        <div className="upload-progress">
                            <Loader2 className="spinner" />
                            Uploading...
                        </div>
                    )}

                    {/* Success Message */}
                    {uploadStatus === 'success' && (
                        <div className="success-message" role="alert">
                            <div className="message-header">
                                <Check className="message-icon" />
                                <strong>Success!</strong>
                            </div>
                            <span className="message-content">
                                Update successfully!<br />
                            </span>
                        </div>
                    )}
                    {showAlert && (
                        <CustomAlert
                            message={alertMessage}
                            onClose={closeCustomAlert}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserProfile;