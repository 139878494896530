import React, { useContext, useState, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import DashboardOverview from '../assets/tutorial/banner.mp4';
import '../styles/AdsForm.css';
import AllTimeStats from './AllTimeStats';

const AdsForm = () => {
  const { address, isConnected } = useAccount();
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const { usdtContract, stakingAddress, stakingContract, web3Token } = useContext(WalletContext);
  const [formData, setFormData] = useState({
    productName: '',
    bannerImageUrl: '',
    promotionDays: '',
    redirectLink: '',
    promotionDuration: '',
    amount: 0
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [explorerLink, setExplorerLink] = useState('');
  const [isApproved, setApproved] = useState(false);
  const [checkApprove, setCheckApprove] = useState(false);
  const [checkAddAds, setCheckAddAds] = useState(false);

  const VideoPopup = ({ onCloseVideo }) => {
    return (
      <div className="video-popup-overlay">
        <div className="video-popup">
          <button className="popup-close-btn" onClick={onCloseVideo}>✕</button>
          <div className='video-popup-container'>
            <iframe className='popup-video popup-video-bacground' src="https://player.vimeo.com/video/1042885624?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Yourmembershipcardvideo">
            </iframe>
          </div>
        </div>
      </div>
    );
  };

  // Contract Reads
  const { data: _allowance } = useReadContract({
    ...usdtContract,
    functionName: 'allowance',
    args: [address, stakingContract],
    enabled: Boolean(address) && Boolean(stakingContract)
  });

  const { data: tokenDecimal } = useReadContract({
    ...usdtContract,
    functionName: 'decimals',
    enabled: Boolean(address)
  });

  // Contract Writes
  const {
    writeContract: writeApprove,
    data: approveData,
    error: approveError,
    isPending: isApprovePending
  } = useWriteContract();

  const {
    writeContract: writeAdPromotion,
    data: adPromotionData,
    error: adPromotionError,
    isPending: isAdPromotionPending
  } = useWriteContract();

  // Transaction Receipts
  const {
    isLoading: isApproveLoading,
    isSuccess: isApproveSuccess,
    error: approveReceiptError,
  } = useWaitForTransactionReceipt({ hash: approveData });

  const {
    isLoading: isAdPromotionLoading,
    isSuccess: isAdPromotionSuccess,
    error: adPromotionReceiptError,
  } = useWaitForTransactionReceipt({ hash: adPromotionData });

  const showCustomAlert = (message, explorerLink = '') => {
    setAlertMessage(message);
    setExplorerLink(explorerLink);
    setShowAlert(true);
  };

  const closeCustomAlert = () => {
    setShowAlert(false);
  };

  // Transaction Status Handlers
  const handleTransactionStart = () => {
    console.log('Transaction started..');
  };

  // Helper function to extract error message
  const getErrorMessage = (error) => {
    if (!error) return 'Unknown error occurred';

    // Check for user rejection message in different parts of the error object
    const errorDetails = error.details || '';
    const errorMessage = error.message || '';
    const shortMessage = error.shortMessage || '';

    // Common user rejection messages
    if (errorDetails.includes('User denied transaction signature') ||
      errorMessage.includes('User denied transaction signature') ||
      shortMessage.includes('User denied transaction signature')) {
      return 'Transaction was rejected by user';
    }

    // Check for specific error types
    if (error.name === 'ContractFunctionExecutionError') {
      // Extract the Details field from the error
      const detailsMatch = error.message.match(/Details: (.*?)(?=\n|$)/);
      if (detailsMatch) {
        return detailsMatch[1];
      }
    }

    // Gas related errors
    if (errorMessage.includes('insufficient funds')) {
      return 'Insufficient funds for gas fee';
    }

    // Network related errors
    if (errorMessage.includes('network') || errorMessage.includes('disconnected')) {
      return 'Network connection error. Please check your connection';
    }

    // Contract related errors
    if (errorMessage.includes('execution reverted')) {
      const revertMatch = errorMessage.match(/execution reverted:(.*?)(?=\n|$)/);
      if (revertMatch) {
        return revertMatch[1].trim();
      }
      return 'Transaction failed during contract execution';
    }

    // If no specific error is found, return the original message or a default
    return error.message || 'Transaction failed. Please try again';
  };

  // Update the handleTransactionEnd to use the new error handling
  const handleTransactionEnd = (text, transactionHash, error) => {
    if (error) {
      const errorMessage = getErrorMessage(error);
      showCustomAlert(errorMessage);
      return;
    }

    if (text && transactionHash) {
      const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
      showCustomAlert(`${text}`, explorerLink);
    } else if (text) {
      showCustomAlert(text);
    }
  };

  const updateApproval = async () => {
    if (!isConnected && !usdtContract) {
      console.log('Not connected with wallet.');
      return;
    }
    try {
      const allowance = await web3Token.methods.allowance(address, stakingAddress).call();
      if (Number(allowance) > 0) {
        setApproved(true);
        getButtonText();
      }
      console.log('Approvel updated.');
    } catch (error) {
      console.error(`Error updating approve:`, error);
    }
  }

  const getButtonText = () => {
    if (isApproved) {
      return `Submit Now`;
    } else {
      return 'Approve USDT';
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Update the payment based on promotion duration
    if (name === 'promotionDuration') {
      let amount = 0;
      switch (value) {
        case '1 month':
          amount = 5;
          break;
        case '2 months':
          amount = 10;
          break;
        case '3 months':
          amount = 15;
          break;
        case '6 months':
          amount = 25;
          break;
        case '1 year':
          amount = 50;
          break;
        default:
          amount = 0;
      }
      setFormData(prevState => ({
        ...prevState,
        amount
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isConnected) {
      showCustomAlert('Wallet not connected.');
      return;
    }
    const amountInWei = (formData.amount) * (10 ** Number(tokenDecimal));
    const adsData = {
      name: formData.productName,
      nonce: 0,
      imageUrl: formData.bannerImageUrl,
      duration: getPromotionDays(formData.promotionDuration),
      redirectLink: formData.redirectLink,
      amount: amountInWei,
      startAt: 0,
      endAt: 0,
      isRunning: false,
      isUpdate: false
    };
    console.log('Ads Data:', adsData);
    if (isApproved) {
      try {
        handleTransactionStart();
        setCheckAddAds(true);
        writeAdPromotion({
          ...stakingContract,
          functionName: 'adPromotion',
          args: [adsData]
        });
      } catch (err) {
        console.error("Transection faild:", err);
        setCheckAddAds(false);
        handleTransactionEnd(null, null, err);
        return;
      }
    } else {
      await approveToken();
    }
  };

  // Main Transaction Functions
  const approveToken = async () => {
    if (!isConnected) {
      showCustomAlert('Please connect your wallet first.');
      return;
    }

    try {
      handleTransactionStart();
      setCheckApprove(true);
      const amountToApprove = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

      writeApprove({
        ...usdtContract,
        functionName: 'approve',
        args: [stakingAddress, amountToApprove]
      });
    } catch (err) {
      console.error(`Error approving}:`, err);
      setCheckApprove(false);
      handleTransactionEnd(null, null, err);
    }
  };

  function getPromotionDays(duration) {
    switch (duration) {
      case '1 month': return 30;
      case '2 months': return 60;
      case '3 months': return 90;
      case '6 months': return 180;
      case '1 year': return 365;
      default: return 0;
    }
  }

  useEffect(() => {
    if (!isConnected && !usdtContract && !address) {
      return;
    }
    const intervalId = setInterval(() => {
      updateApproval();
      getButtonText();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [usdtContract, isConnected, updateApproval, getButtonText]);

  useEffect(() => {
    if (checkApprove) {
      if (approveError || approveReceiptError) {
        handleTransactionEnd(null, null, approveError || approveReceiptError);
        setCheckApprove(false);
      }

      if (isApproveSuccess) {
        handleTransactionEnd('Token approved successfully!');
        setCheckApprove(false);
        setApproved(true);
        getButtonText();
      }
    }
  }, [checkApprove, setCheckApprove, isApproveSuccess, approveError, approveReceiptError]);

  useEffect(() => {
    if (checkAddAds) {
      if (adPromotionError || adPromotionReceiptError) {
        handleTransactionEnd(null, null, adPromotionError || adPromotionReceiptError);
        setCheckAddAds(false);
      }

      if (isAdPromotionSuccess) {
        handleTransactionEnd('Your ads added successfully!');
        setCheckAddAds(false);
      }
    }
  }, [checkAddAds, isAdPromotionSuccess, adPromotionError, adPromotionReceiptError]);

  const isLoading = isApproveLoading || isApprovePending || isAdPromotionLoading || isAdPromotionPending;

  return (
    <div className="product-promotion">
      <button onClick={(e) => setShowVideoPopup(true)} className='watch-tutorial'>Watch Tutorial</button>
      <h2>Promote Your Product</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="productName">Product Name:</label>
          <input
            type="text"
            id="productName"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
            placeholder="Enter product name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="bannerImageUrl">
            Banner Image URL: <span className="banner-size">Size must be 50px height and 120px width.</span>
          </label>
          <input
            type="url"
            id="bannerImageUrl"
            name="bannerImageUrl"
            value={formData.bannerImageUrl}
            onChange={handleChange}
            placeholder="Enter banner image URL"
            required
          />
        </div>

        {formData.bannerImageUrl && (
          <div className="banner-preview">
            <img
              src={formData.bannerImageUrl}
              alt="Product Banner"
              className="banner-image"
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="redirectLink">Redirect Link:</label>
          <input
            type="url"
            id="redirectLink"
            name="redirectLink"
            value={formData.redirectLink}
            onChange={handleChange}
            placeholder="Enter the link"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="promotionDuration">Promotion Duration:</label>
          <select
            id="promotionDuration"
            name="promotionDuration"
            value={formData.promotionDuration}
            onChange={handleChange}
            required
            className='select-theme'
          >
            <option value="" disabled>Select duration</option>
            <option value="1 month">1 Month | $5</option>
            <option value="2 months">2 Months | $10</option>
            <option value="3 months">3 Months | $15</option>
            <option value="6 months">6 Months | $25</option>
            <option value="1 year">1 Year | $50</option>
          </select>
        </div>
        <button type="submit">{getButtonText()}</button>
      </form>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          explorerLink={explorerLink}
          onClose={closeCustomAlert}
        />
      )}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner">
            <img src={loadingSpinner} alt="Loading..." />
          </div>
        </div>
      )}
      {showVideoPopup && (
        <VideoPopup
          video={DashboardOverview}
          onCloseVideo={() => setShowVideoPopup(false)}
        />
      )}
    </div>
  );
};

export default AdsForm;
