// WalletContext.js
import { React, createContext, useState } from 'react';
import ContractABI from './ABI/ImpactXABI.json';
import TokenABI from './ABI/TokenABI.json';
import NftTokenABI from './ABI/nftTokenABI.json';
import TimeDateABI from './ABI/timeABI.json';
import StakingABI from './ABI/StakingABI.json';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [account, setAccount] = useState('');
  const [isConnected, setIsConnected] = useState('');
  const [web3, setWeb3] = useState('');
  const [isApproved, setApproved] = useState(false);
  const [bitcoinExplorerMember, setBitcoinExplorerMember] = useState('');
  const [blockchainProMember, setBlockchainProMember] = useState('');
  const [metaverseMasterMember, setMetaverseMasterMember] = useState('');
  const [aiUltimateProMember, setAiUltimateProMember] = useState('');
  const [referee, setReferee] = useState('');
  const [milestonRewards, setMilestonRewards] = useState('');
  const [totalRefferals, setTotalRefferals] = useState('');
  const [totalEarning, setTotalEarning] = useState('');
  const [todaysEarning, setTodaysEarning] = useState('');
  const [thisMonthEarning, setThisMonthEarning] = useState('');
  const [thisYearEarning, setThisYearEarning] = useState('');
  const [itemSelected, setItemSelected] = useState('');
  const [contractAddress, setContractAddress] = useState('0x2DbF7a48bbcc4827eAf2522Fe25F9cdae1ba64bd');
  const [timeDateAddress, setTimeDateAddress] = useState('0x458DA805b8a016E77dc1e213677de6A59c76c7B0');
  const [stakingAddress, setStakingAddress] = useState('0x6c731D78D65Fc7Ea7654978352D472B1B51abFcb');
  const [nftTokenAddress, setNftTokenAddress] = useState('0xaa73498442667961Ce7fc166a771A2956EEFA208');
  const [tokenAddress, setTokenAddress] = useState('0xc2132D05D31c914a87C6611C10748AEb04B58e8F');
  const [nftMasterAddress, setNftMasterAddress] = useState('0x3906230b0A8a39f80f55695ceE4F5edB61787ab2');
  const [connectedChainId, setConnectedChainId] = useState();
  const [joiningDate, setJoiningDate] = useState();
  const [totalEarned, setTotalEarned] = useState();
  const [usdtDecimal, setUsdtDecimal] = useState();
  const [totalStake, setTotalStake] = useState(0);
  const [totalStakeK, setTotalStakeK] = useState(0);
  const [totalInvestedK, setTotalInvestedK] = useState(0);
  const [totalRefincomK, setTotalRefincomK] = useState(0);
  const [nftValueUsdt, setNftValueUsdt] = useState(0);
  const [pendingReward, setPendingReward] = useState(0);
  const [web3Master, setWeb3Master] = useState();
  const [web3Stake, setWeb3Stake] = useState();
  const [web3Token, setWeb3Token] = useState();
  const [web3Nft, setWeb3Nft] = useState();
  const [web3Data, setWeb3Data] = useState();
  const [referredBy, setReferrer] = useState();
  const [allowanceData, setAllowanceData] = useState();
  const [isAMember, setIsAMember] = useState(false);
  const [usdtContract, setUsdtContract] = useState({ address: tokenAddress, abi: TokenABI });
  const [contract, setContract] = useState({ address: contractAddress, abi: ContractABI });
  const [timeDateContract, setTimeDateContract] = useState({ address: timeDateAddress, abi: TimeDateABI });
  const [stakingContract, setStakingContract] = useState({ address: stakingAddress, abi: StakingABI });
  const [nftContract, setNftContract] = useState({ address: nftTokenAddress, abi: NftTokenABI });

  return (
    <WalletContext.Provider value={{
      account, setAccount, referee, setReferee, isConnected, setIsConnected,
      web3, setWeb3, contract, setContract, stakingContract, setStakingContract,
      bitcoinExplorerMember, setBitcoinExplorerMember, pendingReward, setPendingReward,
      blockchainProMember, setBlockchainProMember, nftValueUsdt, setNftValueUsdt,
      metaverseMasterMember, setMetaverseMasterMember, totalRefincomK, setTotalRefincomK,
      aiUltimateProMember, setAiUltimateProMember, web3Master, setWeb3Master, isAMember, setIsAMember,
      isApproved, setApproved, connectedChainId, setConnectedChainId, web3Token, setWeb3Token,
      contractAddress, setContractAddress, totalEarned, setTotalEarned, web3Nft, setWeb3Nft,
      tokenAddress, setTokenAddress, stakingAddress, setStakingAddress, nftMasterAddress, setNftMasterAddress,
      nftTokenAddress, setNftTokenAddress, totalInvestedK, setTotalInvestedK,
      nftContract, setNftContract, usdtDecimal, setUsdtDecimal, allowanceData, setAllowanceData,
      usdtContract, setUsdtContract, totalStake, setTotalStake, web3Data, setWeb3Data,
      milestonRewards, setMilestonRewards, web3Stake, setWeb3Stake, referredBy, setReferrer,
      totalRefferals, setTotalRefferals, totalStakeK, setTotalStakeK,
      thisMonthEarning, setThisMonthEarning, joiningDate, setJoiningDate,
      todaysEarning, setTodaysEarning, totalEarning, setTotalEarning,
      thisYearEarning, setThisYearEarning, itemSelected, setItemSelected,
      timeDateAddress, setTimeDateAddress, timeDateContract, setTimeDateContract
    }}>
      {children}
    </WalletContext.Provider>
  );
};