import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useAccount, useReadContract, useReadContracts } from 'wagmi';
import Web3 from 'web3';
import { WalletContext } from '../WalletContext';
import ContractABI from '../ABI/ImpactXABI.json';
import CustomAlert from './CustomAlert';
import InvestModul from './InvestModul';
import DashboardOverview from '../assets/tutorial/transactionvideo.mp4';
import '../styles/Transactions.css';
import FlippingCardInfo from './FlippingCardInfo';

const Transactions = () => {
  const { address, isConnected } = useAccount();
  const { totalRefincomK, timeDateContract, referredBy, setReferrer, web3Master, web3Nft, contractAddress,
    usdtContract, contract, nftContract } = useContext(WalletContext);
  const [transactions, setTransactions] = useState([]);
  const [isInvestModalOpen, setInvestModalOpen] = useState(false);
  const [explorerLink, setExplorerLink] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [nameAndIcon, setNameAndIcon] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [cardInfo, setCardInfo] = useState([
    { label: 'Card Number', value: 'No Card' },
    { label: 'Name', value: 'Fan Pass' },
    { label: 'Status', value: 'Node Active 0' },
    { label: 'Value', value: '$ 0.00' },
    { label: 'NRG Amount', value: '$ 0.00' },
    { label: 'Referrer', value: '0x000...000' }
  ]);
  const [showVideoPopup, setShowVideoPopup] = useState(false);

  const { data: getReferredBy } = useReadContract({
    ...contract,
    functionName: 'getReferredBy',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: ownedNftToken } = useReadContract({
    ...contract,
    functionName: 'getOwnedToken',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: typeOfNfts } = useReadContracts({
    contracts: ownedNftToken?.map((nfts) => ({
      ...nftContract,
      functionName: 'characterOf',
      args: [nfts]
    })) ?? [],
    enabled: !!ownedNftToken?.length
  });

  const VideoPopup = ({ onCloseVideo }) => {
    return (
      <div className="video-popup-overlay">
        <div className="video-popup">
          <button className="popup-close-btn" onClick={onCloseVideo}>✕</button>
          <div className='video-popup-container'>
            <iframe className='popup-video popup-video-bacground' src="https://player.vimeo.com/video/1042864274?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Yourmembershipcardvideo">
            </iframe>
          </div>
        </div>
      </div>
    );
  };

  const shortenWalletAddress = useCallback((address) => {
    if (!address) return '';
    const start = address.slice(0, 6);
    const end = address.slice(-4);
    return `${start}***${end}`;
  }, []);

  const getInFormate = (amount) => {
    if (Number(amount) > 0) {
      const theAmount = Number(amount);

      if (theAmount <= 1e3) {
        return (theAmount.toFixed(2));
      } else if (theAmount > 1e3 && theAmount <= 1e6) {
        return (`${(theAmount / 1e3).toFixed(2)} K`);
      } else if (theAmount > 1e6 && theAmount <= 1e9) {
        return (`${(theAmount / 1e6).toFixed(2)} M`);
      } else if (theAmount > 1e9) {
        return (`${(theAmount / 1e9).toFixed(2)} B`);
      } else {
        return 0
      }
    } else {
      return 0;
    }
  }

  const processData = async () => {
    if (isConnected && ownedNftToken && getReferredBy && typeOfNfts) {
      try {
        if (getReferredBy !== '0x0000000000000000000000000000000000000000') {
          setReferrer(shortenWalletAddress(getReferredBy));
        }
        let FanPass;
        let BitcoinExp;
        let MetaverseMaster;
        let BlockchainPro;
        let AiUltimatePro;

        if (ownedNftToken.length > 0) {
          FanPass = {
            totalNft: 0,
            activeNode: 0,
            value: 0,
            nrgValue: 0,
            nftId: 0
          };
          BitcoinExp = {
            totalNft: 0,
            activeNode: 0,
            value: 0,
            nrgValue: 0,
            nftId: 0
          };
          MetaverseMaster = {
            totalNft: 0,
            activeNode: 0,
            value: 0,
            nrgValue: 0,
            nftId: 0
          };
          BlockchainPro = {
            totalNft: 0,
            activeNode: 0,
            value: 0,
            nrgValue: 0,
            nftId: 0
          };
          AiUltimatePro = {
            totalNft: 0,
            activeNode: 0,
            value: 0,
            nrgValue: 0,
            nftId: 0
          };
        }

        if (typeOfNfts) {
          for (let i = 0; i < typeOfNfts.length; i++) {
            if (Number(typeOfNfts[i].result) == 1) {
              FanPass.totalNft += 1;
              FanPass.value += 50;
              FanPass.nftId = Number(ownedNftToken[i]);
            } else if ((typeOfNfts[i].result) == 2) {
              BitcoinExp.totalNft += 1;
              BitcoinExp.activeNode += 1;
              BitcoinExp.value += 500;
              BitcoinExp.nrgValue += 100 * 3334;
              BitcoinExp.nftId = Number(ownedNftToken[i]);
            } else if ((typeOfNfts[i].result) == 3) {
              MetaverseMaster.totalNft += 1;
              MetaverseMaster.activeNode += 2;
              MetaverseMaster.value += 1000;
              MetaverseMaster.nrgValue += 200 * 3334;
              MetaverseMaster.nftId = Number(ownedNftToken[i]);
            } else if ((typeOfNfts[i].result) == 4) {
              BlockchainPro.totalNft += 1;
              BlockchainPro.activeNode += 3;
              BlockchainPro.value += 1500;
              BlockchainPro.nrgValue += 300 * 3334;
              BlockchainPro.nftId = Number(ownedNftToken[i]);
            } else if (typeOfNfts[i].result == 5) {
              AiUltimatePro.totalNft += 1;
              AiUltimatePro.activeNode += 4;
              AiUltimatePro.nrgValue += 500 * 3334;
              AiUltimatePro.nftId = Number(ownedNftToken[i]);
            }
          }
        }

        const availableCards = [];

        // Fan Pass
        if (FanPass.totalNft > 0) {
          availableCards.push({
            cardNumber: FanPass.nftId,
            name: 'Fan Pass',
            status: `Node Active ${FanPass.activeNode}`,
            value: `$ ${getInFormate(FanPass.value)}`,
            nrgAmount: `$ ${getInFormate(FanPass.nrgValue)}`,
            referrer: referredBy
          });
        }

        // Bitcoin Explorer
        if (BitcoinExp.totalNft > 0) {
          availableCards.push({
            cardNumber: BitcoinExp.nftId,
            name: 'Bitcoin Explorer',
            status: `Node Active ${BitcoinExp.activeNode}`,
            value: `$ ${getInFormate(BitcoinExp.value)}`,
            nrgAmount: `$ ${getInFormate(BitcoinExp.nrgValue)}`,
            referrer: referredBy
          });
        }

        // Metaverse Master
        if (MetaverseMaster.totalNft > 0) {
          availableCards.push({
            cardNumber: MetaverseMaster.nftId,
            name: 'Metaverse Master',
            status: `Node Active ${MetaverseMaster.activeNode}`,
            value: `$ ${getInFormate(MetaverseMaster.value)}`,
            nrgAmount: `$ ${getInFormate(MetaverseMaster.nrgValue)}`,
            referrer: referredBy
          });
        }

        // Blockchain Pro
        if (BlockchainPro.totalNft > 0) {
          availableCards.push({
            cardNumber: BlockchainPro.nftId,
            name: 'Metaverse Master',
            status: `Node Active ${BlockchainPro.activeNode}`,
            value: `$ ${getInFormate(BlockchainPro.value)}`,
            nrgAmount: `$ ${getInFormate(BlockchainPro.nrgValue)}`,
            referrer: referredBy
          });
        }

        // AiUltimate Pro
        if (AiUltimatePro.totalNft > 0) {
          availableCards.push({
            cardNumber: AiUltimatePro.nftId,
            name: 'Metaverse Master',
            status: `Node Active ${AiUltimatePro.activeNode}`,
            value: `$ ${getInFormate(AiUltimatePro.value)}`,
            nrgAmount: `$ ${getInFormate(AiUltimatePro.nrgValue)}`,
            referrer: referredBy
          });
        }
        // console.log('Card Info', availableCards);
        setCardInfo(availableCards);
      } catch (error) {
        console.error('Error processing data:', error);
      }
    } else {
      console.log('Not connected with wallet or error on contract.');
    }
  }

    useEffect(() => {
      if (isConnected && ownedNftToken && getReferredBy && typeOfNfts) {
        processData();
      }
    }, []);

    const showCustomAlert = (message, explorerLink = '') => {
      setAlertMessage(message);
      setExplorerLink(explorerLink);
      setShowAlert(true);
    };

    const closeCustomAlert = () => {
      setShowAlert(false);
    };

    const handleInvestModul = () => {
      setInvestModalOpen(true);
    };

    const handleCloseModal = () => {
      setInvestModalOpen(false);
    };

    const { data: tokenDecimal } = useReadContract({
      ...usdtContract,
      functionName: 'decimals',
      enabled: Boolean(address)
    });

    const { data: taxNumbers } = useReadContract({
      ...timeDateContract,
      functionName: 'getTaxNumbers',
      args: [address],
      enabled: Boolean(address)
    });

    const { data: taxData } = useReadContracts({
      contracts: taxNumbers?.map((taxNumber) => ({
        ...timeDateContract,
        functionName: 'getTaxData',
        args: [taxNumber]
      })) ?? [],
      enabled: !!taxNumbers?.length
    });

    const fetchUserIconName = useCallback(async () => {
      if (!isConnected) return;

      try {

      } catch (error) {
        console.error('Error fetching referral icon and name.', error);
      }
    }, [address, contractAddress, isConnected]);

    useEffect(() => {
      fetchUserIconName();
    }, [fetchUserIconName]);

    const fetchTransactions = useCallback(async () => {
      try {
        const fetchedTransactions = await Promise.all(taxData.map(async (taxNum) => {
          const typeNumber = Number(taxNum.result.types);
          let type = 'Unknown';
          let iconType = 'health';

          switch (typeNumber) {
            case 1: type = 'Purchase'; break;
            case 2: type = 'Referral'; break;
            case 3: type = 'Withdraw'; break;
            case 4: type = 'Claim Reward'; break;
            case 5: type = 'Reinvest Reward'; break;
            case 6: type = 'Reinvest Referral Reward'; break;
            case 7: type = 'Claim Business Reward'; break;
            case 8: type = 'Withdraw Referral'; break;
            case 9: type = 'Received Reward'; break;
            case 10: type = 'Sell/Buy NFTs'; break;
            case 11: type = 'Buy/Sell NFTs'; break;
            case 12: type = 'NFT Sell Offer'; break;
            case 16: type = 'NFT Gifted'; break;
            default: type = 'Unknown';
          }

          const date = new Date(
            Number(taxNum.result.year),
            Number(taxNum.result.month) - 1,
            Number(taxNum.result.day),
            Number(taxNum.result.hour) - 1,
            Number(taxNum.result.minute),
            Number(taxNum.result.second)
          );
          const formattedDate = date.toUTCString();
          const _taxAmount = Number(taxNum.result.amount);
          const taxAmount = (_taxAmount / (10 ** 6)).toFixed(2);

          return {
            id: shortenWalletAddress(taxNum.result.userAddress),
            type: type,
            amount: taxAmount,
            time: formattedDate,
            icon: iconType
          };
        }));

        setTransactions(fetchedTransactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    }, [taxData, shortenWalletAddress]);

    useEffect(() => {
      if (!isConnected) return;

      fetchTransactions();
    }, [isConnected, fetchTransactions, taxData]);

    const renderIcon = (type) => {
      switch (type) {
        case 'health':
          return <div className="icon health-icon">♥</div>;
        case 'user':
          return <div className="icon user-icon">👤</div>;
        case 'lightning':
          return <div className="icon lightning-icon">⚡</div>;
        default:
          return null;
      }
    };

    const formatAmount = (amount) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount);
    };

    useEffect(() => {
      const cardInterval = setInterval(() => {
        let nextNumber = currentCardIndex;
        console.log('Card index', nextNumber);
        setCurrentCardIndex(nextNumber);
      }, 5000);
      return () => clearInterval(cardInterval);
    }, []);

    return (
      <>
        <div className='video-tran-container'>
          <button onClick={(e) => setShowVideoPopup(true)} className='watch-tutorial'>Watch Tutorial</button>
          <div className='transection-container'>
            <div className="left-dashboard">
              <div className='left-container'>
                <div className="earnings-card">
                  <div className="earnings-header">
                    <h1>Referral Earnings</h1>
                    <span className="amount">${totalRefincomK}</span>
                    <button className="more-options">•••</button>
                  </div>
                </div>

                <div className="transactions-card">
                  <div className="transactions-header">
                    <h1>Transaction</h1>
                    <button className="more-options">•••</button>
                  </div>

                  <div className="transactions-list">
                    {transactions.map((tx) => (
                      <div key={tx.id} className="transaction-item">
                        <div className="transaction-left">
                          {renderIcon(tx.icon)}
                          <div className="transaction-info">
                            <div className="transaction-id">{tx.id}</div>
                            <div className="transaction-time">{tx.time}</div>
                          </div>
                        </div>
                        <div className="transaction-right">
                          <div className="transaction-details">
                            <div className="transaction-type">{tx.type}</div>
                            <div className="transaction-level">{tx.level}</div>
                          </div>
                          <div className="transaction-amount">
                            {formatAmount(tx.amount)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="team-card">
                  <div className="team-header">
                    <h1>Team</h1>
                    <button className="more-options">•••</button>
                  </div>
                  <div className="team-members">
                    {nameAndIcon.map((member) => (
                      <div key={member.name} className="team-member">
                        <img
                          className="member-avatar"
                          src={member.icon}
                        ></img>
                        <span className="member-name">{member.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='right-dashboard'>
              <div className="sidebar">
                <div className="membership-card">
                  <div className="membership-header">
                    <button onClick={handleInvestModul} className='upgrade-button'>Upgrade</button>
                    <span className="star">⭐</span>
                  </div>
                  <h2>MEMBERSHIP</h2>
                  <p>Upgrade your membership to get more perks and a NRG token boost.</p>
                  <div className="arrows">›››</div>
                </div>

                <FlippingCardInfo cards={cardInfo} />

                <button onClick={handleInvestModul} className="add-node-button">
                  Add Node
                </button>
              </div>
            </div>
          </div>
        </div>
        {showAlert && (
          <CustomAlert
            message={alertMessage}
            explorerLink={explorerLink}
            onClose={closeCustomAlert}
          />
        )}
        {showVideoPopup && (
          <VideoPopup
            video={DashboardOverview}
            onCloseVideo={() => setShowVideoPopup(false)}
          />
        )}

        {isInvestModalOpen && (
          <div className="modul-overlay">
            <div className="modul-container">
              <button
                className="modul-close-button"
                onClick={handleCloseModal}
                aria-label="Close investment modul"
              >
                ✕
              </button>
              <InvestModul
                isOpen={isInvestModalOpen}
                onClose={isInvestModalOpen}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  export default Transactions;