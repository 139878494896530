import React, { useState, useContext, useEffect, useCallback } from 'react';
import Web3 from 'web3';
import dataLabAbi from '../ABI/timeABI.json';
import TokenABI from '../ABI/TokenABI.json';
import MasterABI from '../ABI/ImpactXABI.json';
import { WalletContext } from '../WalletContext';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import FanPass from '../assets/membervideos/fanpass.mp4';
import BitcoinExplorer from '../assets/membervideos/bitcoinexplore.mp4';
import BlockchainPro from '../assets/membervideos/blockchainpro.mp4';
import MetaverseMaster from '../assets/membervideos/metaversemaster.mp4';
import AiUltimatePro from '../assets/membervideos/aiultimate.mp4';
import DashboardOverview from '../assets/tutorial/dashboard-overview.mp4';
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt, useBalance } from 'wagmi';

const InvestModul = ({ isOpen, onClose }) => {
  const { address, isConnected } = useAccount();
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const web3 = new Web3('http://127.0.0.1:7545');
  const initialCardData = [
    {
      title: 'FAN PASS',
      video: FanPass,
      price: 50,
      features: {
        'Referral Program': true,
        'Basic Dapp Access': true,
        'Earn NRG tokens': true,
        'Networking Events': true,
      },
      ActiveFeaturs1: 'Referral Program',
      ActiveFeaturs2: 'Basic Dapp Access',
      ActiveFeaturs4: 'Earn NRG tokens',
      ActiveFeaturs5: 'Networking Events'
    },
    {
      title: 'BITCOIN EXPLORER',
      video: BitcoinExplorer,
      price: 500,
      features: {
        '1 NRG Active Node': true,
        'Dapp Access Level 1': true,
        'Bitcoin Education': true,
        'Networking Events': true,
        'Digital Vault Share': true,
      },
      ActiveFeaturs1: '1 NRG Active Node',
      ActiveFeaturs2: 'Dapp Access Level 1',
      ActiveFeaturs3: 'Bitcoin Education',
      ActiveFeaturs4: 'Networking Events',
      ActiveFeaturs5: 'Digital Vault Share'
    },
    {
      title: 'BLOCKCHAIN PRO',
      video: BlockchainPro,
      price: 1000,
      features: {
        '2 NRG Active Nodes': true,
        'Dapp Access Level 2': true,
        'Blockchain Education': true,
        'Networking Events': true,
        'Digital Vault Share': true,
      },
      ActiveFeaturs1: '2 NRG Active Nodes',
      ActiveFeaturs2: 'Dapp Access Level 2',
      ActiveFeaturs3: 'Blockchain Education',
      ActiveFeaturs4: 'Networking Events',
      ActiveFeaturs5: 'Digital Vault Share'
    },
    {
      title: 'METAVERSE MASTER',
      video: MetaverseMaster,
      price: 1500,
      features: {
        '3 NRG Active Nodes': true,
        'Dapp Access Level 3': true,
        'Metaverse Education': true,
        'Metaverse Land': true,
        'Digital Vault Share': true,
      },
      ActiveFeaturs1: '3 NRG Active Nodes',
      ActiveFeaturs2: 'Dapp Access Level 3',
      ActiveFeaturs3: 'Metaverse Education',
      ActiveFeaturs4: 'Metaverse Land',
      ActiveFeaturs5: 'Digital Vault Share'
    },
    {
      title: 'AI ULTIMATE PRO',
      video: AiUltimatePro,
      price: 2500,
      features: {
        '4 NRG Active Nodes': true,
        'Dapp Access Level 4': true,
        'AI Education': true,
        'AI Related Digital Assets': true,
        'Digital Vault Share': true,
      },
      ActiveFeaturs1: '4 NRG Active Nodes',
      ActiveFeaturs2: 'Dapp Access Level 4',
      ActiveFeaturs3: 'AI Education',
      ActiveFeaturs4: 'AI Related Digital Assets',
      ActiveFeaturs5: 'Digital Vault Share'
    },
  ];

  const [cardData, setCardData] = useState(initialCardData);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardType, setCardType] = useState();
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [explorerLink, setExplorerLink] = useState('');
  const [cards, setNumberOfMembership] = useState(1);
  const [checkApprove, setCheckApprove] = useState(false);
  const [checkEnrol, setCheckEnrol] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { referee, contract, usdtContract, allowanceData,
    isApproved, setApproved, setAllowanceData,
    contractAddress, tokenAddress, timeDateAddress
  } = useContext(WalletContext);

  // Contract Reads
  const { data: tokenDecimal } = useReadContract({
    ...usdtContract,
    functionName: 'decimals',
    enabled: Boolean(address)
  });

  const { data: tokenBalance } = useReadContract({
    ...usdtContract,
    functionName: 'balanceOf',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: approvedAmount } = useReadContract({
    ...usdtContract,
    functionName: 'allowance',
    args: [address, contractAddress],
    enabled: !isApproved
  });

  const { data: currentApprovedAmount } = useReadContract({
    ...usdtContract,
    functionName: 'allowance',
    args: [address, contractAddress],
    enabled: isApproved
  });

  const nativeBalance = useBalance({
    address: address,
    unit: 'ether',
    enabled: Boolean(address)
  });

  const { data: isMamber } = useReadContract({
    ...contract,
    functionName: 'isAMamber',
    args: [address],
    enabled: Boolean(address)
  });

  // Contract Writes
  const {
    writeContract: writeApprove,
    data: approveData,
    error: approveError,
    isPending: isApprovePending
  } = useWriteContract();

  const {
    writeContract: writePurchase,
    data: purchaseData,
    error: purchaseError,
    isPending: isPurchasePending
  } = useWriteContract();

  // Transaction Status Handlers
  const handleTransactionStart = () => {
    console.log('Transaction started..');
  };

  // Helper function to extract error message
  const getErrorMessage = (error) => {
    if (!error) return 'Unknown error occurred';

    // Check for user rejection message in different parts of the error object
    const errorDetails = error.details || '';
    const errorMessage = error.message || '';
    const shortMessage = error.shortMessage || '';

    // Common user rejection messages
    if (errorDetails.includes('User denied transaction signature') ||
      errorMessage.includes('User denied transaction signature') ||
      shortMessage.includes('User denied transaction signature')) {
      return 'Transaction was rejected by user';
    }

    // Check for specific error types
    if (error.name === 'ContractFunctionExecutionError') {
      // Extract the Details field from the error
      const detailsMatch = error.message.match(/Details: (.*?)(?=\n|$)/);
      if (detailsMatch) {
        return detailsMatch[1];
      }
    }

    // Gas related errors
    if (errorMessage.includes('insufficient funds')) {
      return 'Insufficient funds for gas fee';
    }

    // Network related errors
    if (errorMessage.includes('network') || errorMessage.includes('disconnected')) {
      return 'Network connection error. Please check your connection';
    }

    // Contract related errors
    if (errorMessage.includes('execution reverted')) {
      const revertMatch = errorMessage.match(/execution reverted:(.*?)(?=\n|$)/);
      if (revertMatch) {
        return revertMatch[1].trim();
      }
      return 'Transaction failed during contract execution';
    }

    // If no specific error is found, return the original message or a default
    return error.message || 'Transaction failed. Please try again';
  };

  // Update the handleTransactionEnd to use the new error handling
  const handleTransactionEnd = (text, transactionHash, error) => {
    if (error) {
      const errorMessage = getErrorMessage(error);
      showCustomAlert(errorMessage);
      return;
    }

    if (text && transactionHash) {
      const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
      showCustomAlert(`${text}`, explorerLink);
    } else if (text) {
      showCustomAlert(text);
    }
  };

  // Alert Handlers
  const showCustomAlert = (message, explorerLink = '') => {
    setAlertMessage(message);
    setExplorerLink(explorerLink);
    if (message) {
      setShowAlert(true);
    }
  };

  const closeCustomAlert = () => {
    setShowAlert(false);
    setAlertMessage(null);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // const handleUserNameInput = (inputValue) => {
  //   // console.log('Input value:', inputValue);
  //   if (inputValue.length > 5) {
  //     showCustomAlert('Username must be 5 characters or less');
  //   } else {
  //     setNicName(inputValue);
  //   }
  // };

  const handleViewCards = async (card, cardPrice) => {
    setSelectedCard(card);
    if (cardPrice === 50) {
      setCardType(1);
    } else if (cardPrice === 500) {
      setCardType(2);
    } else if (cardPrice === 1000) {
      setCardType(3);
    } else if (cardPrice === 1500) {
      setCardType(4);
    } else {
      setCardType(5);
    }
    await updateApproval(cardPrice);
  };

  // const handleUpload = async () => {
  //   const formData = new FormData();
  //   formData.append('file', selectedFile);

  //   try {
  //     let response;
  //     if (!iconUrl) {
  //       response = await fetch('http://localhost:5000/upload', {
  //         method: 'POST',
  //         body: formData,
  //       });
  //     } else {
  //       return true;
  //     }

  //     if (response.ok) {
  //       console.log('File uploaded successfully!', response);
  //       setIconUrl(true);
  //       return true;
  //     } else {
  //       console.log('Failed to upload file.');
  //       return false;
  //     }
  //   } catch (error) {
  //     console.log('Error file uploade', error);
  //     handleTransactionEnd(null, null, error);
  //     return false;
  //   }
  // };

  const updateApproval = async (cardPrice) => {
    console.log('Approve Amount', approvedAmount);
    if (isConnected && approvedAmount) {
      if (Number(approvedAmount) >= cardPrice) {
        setApproved(true);
      } else {
        isApproved(false);
      }
    } else if (isConnected && currentApprovedAmount) {
      if (Number(approvedAmount) >= cardPrice) {
        setApproved(true);
      } else {
        isApproved(false);
      }
    }
  }

  const getButtonText = () => {
    if (isApproved) {
      return `Join Now`;
    } else {
      return 'Enable To Join';
    }
  };

  const approveToken = async () => {
    if (!isConnected) {
      showCustomAlert('Please connect your wallet first.');
      return;
    }

    try {
      handleTransactionStart();
      setCheckApprove(true);
      const amountToApprove = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

      writeApprove({
        ...usdtContract,
        functionName: 'approve',
        args: [contractAddress, amountToApprove]
      });
    } catch (err) {
      console.error(`Error approving ${tokenAddress.toUpperCase()}:`, err);
      handleTransactionEnd('Transaction failed. Please try again.');
      setCheckApprove(false);
    }
  };

  const enrollMembership = async () => {
    if (referee === 'nolinkfound') {
      showCustomAlert('To join our platform, please contact the support team for a referral link. Without a valid referral, you won\'t be able to access the full features of our dApp.');
      return;
    }

    if (!isConnected) {
      showCustomAlert('Please connect with wallet.');
      return;
    }
    const investAmount = ((selectedCard.price * cards) * (10 ** 6));
    // console.log('Approve Data:', contractAddress, showAlert, cardType, cards, investAmount);
    if (Number(approvedAmount) >= investAmount) {
      setApproved(true);
      setAllowanceData(Number(approvedAmount));
      getButtonText();
    }

    if (approvedAmount >= investAmount) {
      try {
        // const isUploaded = await handleUpload();
        // if (!isUploaded) {
        //   showCustomAlert('Error uploading file.');
        //   return;
        // };
        // if (!isAMember && nicName === '') {
        //   showCustomAlert('Please enter your user name!');
        //   return;
        // }
        // if (!isAMember && selectedFile === null) {
        //   showCustomAlert('Please add profile image first!');
        //   return;
        // }
        let refereeAddress = referee;
        if (referee === 'Unknown User' && !isMamber) {
          console.log('Referred by:', referee, isMamber);
          showCustomAlert('Invalid referral link, please use a valid referral link.');
          return;
        } else if (isMamber) {
          refereeAddress = '0xa71EAbEd80D6125CF4c3863AAD50D68B00335CaD';
        }
        handleTransactionStart();
        setCheckEnrol(true);
        console.log('Approve Data:', selectedFile);
        // const imageUrl = `http://localhost:10001/uploads/${selectedFile.name}`;
        writePurchase({
          ...contract,
          functionName: 'purchaseMembership',
          args: [address, refereeAddress, cardType, cards, false]
        });
      } catch (err) {
        console.error("Investment failed:", err);
        handleTransactionEnd('Transaction failed or rejected.');
        setCheckEnrol(false);
      }
    } else {
      await approveToken();
    }
  };

  const updateVideosUrl = useCallback(async () => {
    try {
      console.log(`Updating video URL`);
      const contractInstance = new web3.eth.Contract(dataLabAbi, timeDateAddress);

      const videosNonce = await contractInstance.methods.videoNonce().call();
      const videosNonceNumber = Number(videosNonce);
      const updatedCardData = [...cardData];

      if (videosNonceNumber == 0) {
        console.log('No videos found');
        return;
      }

      for (let i = 1; i <= videosNonceNumber; i++) {
        const videosData = await contractInstance.methods.getVideoData(i).call();

        if (videosData.types === 'Metaverse Master') {
          updatedCardData[2].video = videosData.url;
        }
        if (videosData.types === 'Bitcoin Explorer') {
          updatedCardData[0].video = videosData.url;
        }
        if (videosData.types === 'Blockchain Pro') {
          updatedCardData[1].video = videosData.url;
        }
        if (videosData.types === 'Ai Ultimate') {
          updatedCardData[3].video = videosData.url;
        }
      }

      setCardData(updatedCardData);
      console.log('Card data updated with videos:', updatedCardData);

    } catch (error) {
      console.error('Error updating URL:', error);
    }
  }, [cardData]);

  // Transaction Receipts
  const {
    isLoading: isApproveLoading,
    isSuccess: isApproveSuccess,
    error: approveReceiptError,
  } = useWaitForTransactionReceipt({ hash: approveData });

  const {
    isLoading: isPurchaseLoading,
    isSuccess: isPurchaseSuccess,
    error: purchaseReceiptError,
  } = useWaitForTransactionReceipt({ hash: purchaseData });

  useEffect(() => {
    updateVideosUrl();
    updateApproval();
  }, [updateVideosUrl]);

  const VideoPopup = ({ video, onCloseVideo }) => {
    return (
      <div className="video-popup-overlay">
        <div className="video-popup">
          <button className="popup-close-btn" onClick={onCloseVideo}>✕</button>
          <video className='popup-video-bacground' src={video} controls autoPlay width="100%">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  };

  // Effects
  useEffect(() => {
    if (checkApprove) {
      if (approveError || approveReceiptError) {
        handleTransactionEnd(null, null, approveError || approveReceiptError);
        setCheckApprove(false);
      }

      if (isApproveSuccess) {
        handleTransactionEnd('Token approved successfully!');
        setCheckApprove(false);
        setApproved(true);
        getButtonText();
      }
    }
  }, [checkApprove, isApproveSuccess, approveError, approveReceiptError]);

  useEffect(() => {
    if (checkEnrol) {
      if (purchaseError || purchaseReceiptError) {
        handleTransactionEnd(null, null, purchaseError || purchaseReceiptError);
        setCheckEnrol(false);
      }

      if (isPurchaseSuccess) {
        handleTransactionEnd(`You have successfully purchase ${selectedCard.title} NFT!`);
        setCheckEnrol(false);
        setShowVideoPopup(true);
      }
    }
  }, [checkEnrol, isPurchaseSuccess, purchaseError || purchaseReceiptError]);

  const isLoading = isApprovePending || isApproveLoading || isPurchasePending || isPurchaseLoading;

  const renderCardDetails = () => {
    if (!selectedCard) return null;

    return (
      <div className="card-details-overlay">
        <div className="card-details">
          <h2>Buy VIP Membership Cards</h2>
          <div className="card-details-content">
            <div className="card-image">
              <div className="placeholder-image">
                <video src={selectedCard.video} controls autoPlay loop width="100%" height="100%" />
              </div>
            </div>
            <div className="card-info">
              <h3>{selectedCard.title}</h3>
              <div className='price-number'>
                <p className="card-price">${selectedCard.price}</p>
                <input
                  className='card-input'
                  type='number'
                  placeholder='Number of card'
                  value={cards}
                  onChange={(e) => setNumberOfMembership(e.target.value)}
                />
              </div>
              <ul className="feature-list">
                {Object.entries(selectedCard.features).map(([feature, included]) => (
                  <li key={feature}>
                    {feature}
                    <span className={included ? 'included' : 'not-included'}>
                      {included ? '✓' : '✗'}
                    </span>
                  </li>
                ))}
              </ul>
              <button className="join-now-btn" onClick={enrollMembership}>
                {getButtonText()}
              </button>
            </div>
          </div>
          <button className="close-btn" onClick={() => setSelectedCard(null)}>✕</button>
        </div>
        {showAlert && (
          <CustomAlert
            message={alertMessage}
            explorerLink={explorerLink}
            onClose={closeCustomAlert}
          />
        )}
        {showVideoPopup && (
          <VideoPopup
            video={DashboardOverview}
            onCloseVideo={() => setShowVideoPopup(false)}
          />
        )}
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner">
              <img src={loadingSpinner} alt="Loading..." />
            </div>
          </div>
        )}
      </div>
    );
  };

  // Main Render
  if (!isOpen) return null;

  return (
    <div>
      <div className="vip-membership-cards-modul">
        <div className="cards-container">
          {cardData.map((card, index) => (
            <div key={index} className="card">
              <h3>{card.title}</h3>
              <p className="price">${card.price}</p>
              <ul className="features">
                {Object.entries(card.features).map(([feature, included], i) => (
                  <li key={i} className={included ? 'active-feature' : 'inactive-feature'}>
                    {feature}
                    <span className={included ? 'checkmark' : 'cross'}>
                      {included ? '✓' : '✗'}
                    </span>
                  </li>
                ))}
              </ul>
              <button className="view-cards-btn" onClick={() => handleViewCards(card, card.price)}>
                Get Started
              </button>
            </div>
          ))}
        </div>
        {renderCardDetails()}
      </div>
    </div>
  );
};

export default InvestModul;