import React, { useState, useContext } from 'react';
import DecentralizedApps from './DecentralizedApps';
import DashboardOverview from '../assets/tutorial/serviceanddappvideo.mp4';
import image1 from '../assets/dapp/16.jpg';
import image2 from '../assets/dapp/5.jpg';
import { WalletContext } from '../WalletContext';
import { useAccount, useReadContract, useReadContracts } from 'wagmi';
import '../styles/ServiceDApp.css';

const ServiceDApp = () => {
    const { address, isConnected } = useAccount();
    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const { nftContract } = useContext(WalletContext);

    const { data: getOwnedTokens } = useReadContract({
        ...nftContract,
        functionName: 'getOwnedTokens',
        args: [address],
        enabled: !!address
    });

    const { data: characters } = useReadContracts({
        contracts: getOwnedTokens?.map((ids) => ({
            ...nftContract,
            functionName: 'characterOf',
            args: [ids]
        })) ?? [],
        enabled: !!getOwnedTokens?.length
    });

    const getNftsTypes = (type) => {
        let isTrue = false;
        if (isConnected && characters) {
            console.log('Nfts characters', characters);
            for (let i = 0; i < characters.length; i++) {
                if (Number(characters[i].result) == type) {
                    isTrue = true;
                }
            }
        } else {
            console.log('Nfts characters', characters);
        }
        return isTrue;
    }

    const buttonSmartcoffeeClick = (e) => {
        e.preventDefault();
        window.location.href = 'https://smartcoffee.ai/';
    }

    const buttonVarianseClick = (e) => {
        e.preventDefault();
        window.location.href = 'http://localhost:3001/';
    }

    const VideoPopup = ({ onCloseVideo }) => {
        return (
            <div className="video-popup-overlay">
                <div className="video-popup">
                    <button className="popup-close-btn" onClick={onCloseVideo}>✕</button>
                    <div className='video-popup-container'>
                        <iframe className='popup-video popup-video-bacground' src="https://player.vimeo.com/video/1042885008?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Yourmembershipcardvideo">
                        </iframe>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='service-dapp-container'>
            <button onClick={(e) => setShowVideoPopup(true)} className='watch-tutorial'>Watch Tutorial</button>
            <div className='available-service-dapp decentralized-apps'>
                <h3 className='section-lavel'>Unlocked Services & DApps</h3>
                <div className="decentralized-apps">
                    <div className="app-grid">
                        {getNftsTypes(2) && (
                            <div className="app-card">
                                <img className='dapp-image' src={image1} alt="ChainTrack Pro" />
                                <div className="h3-p">
                                    <h3>Copy Trading Solutions</h3>
                                    <p>We deliver customized strategies and advanced solutions for copy trading, enabling seamless automation and smarter financial decisions.</p>
                                </div>
                                <button onClick={(e) => buttonVarianseClick(e)} className='launch-app'>Launch App</button>
                            </div>
                        )}
                        {getNftsTypes(2) && (
                            <div className="app-card">
                                <img className='dapp-image' src={image2} alt="FlavorFunds" />
                                <div className="h3-p">
                                    <h3>FlavorFunds</h3>
                                    <p>Share your taste, earn rewards with FlavorFunds. IMTX tokens incentivize feedback on products, creating a community-driven marketplace for taste exploration.</p>
                                </div>
                                <button onClick={(e) => buttonSmartcoffeeClick(e)} className='launch-app'>Launch App</button>
                            </div>
                        )}
                        {(isConnected && characters && characters.length == 0) && <p>Upgrade your membership!</p>}
                    </div>
                </div>
            </div>
            <div className='all-dapp'>
                <h3 className='section-lavel'>All Services And DApps</h3>
                <DecentralizedApps />
            </div>
            {showVideoPopup && (
                <VideoPopup
                    video={DashboardOverview}
                    onCloseVideo={() => setShowVideoPopup(false)}
                />
            )}
        </div>
    );
};

export default ServiceDApp;