import { useState, useEffect } from 'react';

const FlippingCardInfo = ({ cards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipping, setIsFlipping] = useState(false);
  
  useEffect(() => {
    if (!cards || cards.length === 0) return;

    const intervalId = setInterval(() => {
      setIsFlipping(true);
      
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % cards.length);
        
        setTimeout(() => {
          setIsFlipping(false);
        }, 150);
      }, 150);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [cards]);

  const currentCard = cards?.[currentIndex] || {
    cardNumber: 'No Card',
    name: 'Fan Pass',
    status: 'Node Active 0',
    value: '$ 0.00',
    nrgAmount: '$ 0.00',
    referrer: '0x000...000'
  };

  // Function to determine card background class based on name
  const getCardClassName = (name) => {
    switch (name) {
      case 'Fan Pass':
        return 'card-face fan-pass';
      case 'Bitcoin Explorer':
        return 'card-face bitcoin-explorer';
      case 'Metaverse Master':
        return 'card-face metaverse-master';
      case 'Blockchain Pro':
        return 'card-face blockchain-pro';
      case 'AI Ultimate Pro':
        return 'card-face ai-ultimate';
      default:
        return 'card-face default-card';
    }
  };

  return (
    <div className="my-card-info">
      <div className="my-card-info-header">
        <h3>Card Info</h3>
        <button className="more-options">•••</button>
      </div>
      <div className={`card-container ${isFlipping ? 'flipping' : ''}`}>
        <div className={getCardClassName(currentCard.name)}>
          <div className="card-content">
            <div className="info-row">
              <span className="info-label">NFT Id</span>
              <span className="info-value">{currentCard.cardNumber}</span>
            </div>
            <div className="info-row">
              <span className="info-label">Name</span>
              <span className="info-value">{currentCard.name}</span>
            </div>
            <div className="info-row">
              <span className="info-label">Status</span>
              <span className="info-value">{currentCard.status}</span>
            </div>
            <div className="info-row">
              <span className="info-label">Value</span>
              <span className="info-value">{currentCard.value}</span>
            </div>
            <div className="info-row">
              <span className="info-label">NRG Amount</span>
              <span className="info-value">{currentCard.nrgAmount}</span>
            </div>
            <div className="info-row">
              <span className="info-label">Referrer</span>
              <span className="info-value">{currentCard.referrer}</span>
            </div>
          </div>
          <div className="card-decoration-circle"></div>
          <div className="card-decoration-lines"></div>
        </div>
      </div>
    </div>
  );
};

export default FlippingCardInfo;