import React, { useState, useContext, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import ManLogo from '../assets/MaleLogo.png';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import '../styles/WithdrawModal.css';

const WithdrawModal = ({ isOpen, onClose }) => {
    const { totalStakeK, totalTokenStakedWithoutK, remainingDays, stakingContract, usdtContract } = useContext(WalletContext);
    const { address } = useAccount();
    const [amount, setAmount] = useState('');
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [explorerLink, setExplorerLink] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isWithdraw, setIsWithdraw] = useState(false);
    const [willCheck, setWillCheck] = useState(false);

    const { data: totalStakeAmount } = useReadContract({
        ...stakingContract,
        functionName: 'depositedTokens',
        args: [address],
        enabled: Boolean(address)
    });

    const { data: tokenDecimal } = useReadContract({
        ...usdtContract,
        functionName: 'decimals',
        enabled: Boolean(address)
    });

    const {
        writeContract: writeWithdraw,
        data: withdrawData,
        error: withdrawError,
        isPending: isWithdrawPending
    } = useWriteContract();

    const {
        isLoading: isWithdrawLoading,
        isSuccess: isWithdrawSuccess,
        error: withdrawReceiptError,
    } = useWaitForTransactionReceipt({ hash: withdrawData });

    const showCustomAlert = (message, explorerLink = '') => {
        setAlertMessage(message);
        setExplorerLink(explorerLink);
        setShowAlert(true);
    };

    const closeCustomAlert = () => {
        setShowAlert(false);
    };

    const handleTransactionStart = () => {
        setWillCheck(true);
        console.log('Transaction started..');
    };

    // Helper function to extract error message
    const getErrorMessage = (error) => {
        if (!error) return 'Unknown error occurred';

        // Check for user rejection message in different parts of the error object
        const errorDetails = error.details || '';
        const errorMessage = error.message || '';
        const shortMessage = error.shortMessage || '';

        // Common user rejection messages
        if (errorDetails.includes('User denied transaction signature') ||
            errorMessage.includes('User denied transaction signature') ||
            shortMessage.includes('User denied transaction signature')) {
            return 'Transaction was rejected by user';
        }

        // Check for specific error types
        if (error.name === 'ContractFunctionExecutionError') {
            // Extract the Details field from the error
            const detailsMatch = error.message.match(/Details: (.*?)(?=\n|$)/);
            if (detailsMatch) {
                return detailsMatch[1];
            }
        }

        // Gas related errors
        if (errorMessage.includes('insufficient funds')) {
            return 'Insufficient funds for gas fee';
        }

        // Network related errors
        if (errorMessage.includes('network') || errorMessage.includes('disconnected')) {
            return 'Network connection error. Please check your connection';
        }

        // Contract related errors
        if (errorMessage.includes('execution reverted')) {
            const revertMatch = errorMessage.match(/execution reverted:(.*?)(?=\n|$)/);
            if (revertMatch) {
                return revertMatch[1].trim();
            }
            return 'Transaction failed during contract execution';
        }

        // If no specific error is found, return the original message or a default
        return error.message || 'Transaction failed. Please try again';
    };

    // Update the handleTransactionEnd to use the new error handling
    const handleTransactionEnd = (text, transactionHash, error) => {
        setIsWithdraw(false);

        if (error) {
            const errorMessage = getErrorMessage(error);
            showCustomAlert(errorMessage);
            setWillCheck(false);
            return;
        }

        if (text && transactionHash) {
            const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
            showCustomAlert(`${text}`, explorerLink);
        } else if (text) {
            showCustomAlert(text);
        }
        setWillCheck(false);
    };

    const handleMaxClick = () => {
        const depositAmount = Number(totalStakeAmount) / (10 ** Number(tokenDecimal));
        const amountDeposit = (depositAmount / 41675).toFixed(0);
        // console.log('Max click', tokenDecimal);
        const depositedTokenString = amountDeposit * 41675;
        setAmount(depositedTokenString.toFixed(2));
        setWithdrawAmount((depositedTokenString * (10 ** Number(tokenDecimal))).toFixed(0));
    };

    const handleInput = (amount) => {
        setAmount(amount);
        setWithdrawAmount(0);
    }

    function shortenWalletAddress(address) {
        if (!address) return '';
        const start = address.slice(0, 6);
        const end = address.slice(-4);
        return `${start}***${end}`;
    }

    const referralURL = address ? `https://enter.impactxpro.com/referral/${address}` : '';
    const shortAddress = shortenWalletAddress(address);

    const copyLink = () => {
        if (referralURL) {
            navigator.clipboard.writeText(referralURL)
                .then(() => {
                    console.log('Referral URL copied to clipboard');
                    showCustomAlert('Referral URL copied!');
                })
                .catch((err) => {
                    console.error('Failed to copy referral URL: ', err);
                });
        } else {
            console.error('No referral URL available');
        }
    };

    const handleSubmit = async () => {
        if (totalTokenStakedWithoutK < 41675) {
            showCustomAlert('Minimum amount to withdraw is 41675 NRG!');
            return;
        }
        let unstakeAmount = 0;
        if (withdrawAmount == 0) {
            const _unstakeAmount = (amount * (10 ** Number(tokenDecimal)));
            unstakeAmount = _unstakeAmount.toFixed(0);
        } else {
            unstakeAmount = withdrawAmount;
        }
        if (unstakeAmount > 0) {
            try {
                console.log("Going to withdraw stake amount..");
                handleTransactionStart();
                writeWithdraw({
                    ...stakingContract,
                    functionName: 'withdraw',
                    args: [unstakeAmount]
                });
            } catch (error) {
                console.log('Unstake amount', unstakeAmount);
                console.error("Error withdrow stakin amount", error);
                handleTransactionEnd('Transaction failed or rejected.');
                setIsWithdraw(false);
            }
        }
    };

    // Effects
    useEffect(() => {
        if (willCheck) {
            if (withdrawError || withdrawReceiptError) {
                handleTransactionEnd(null, null, withdrawError || withdrawReceiptError);
                setIsWithdraw(false);
            }

            if (isWithdrawSuccess) {
                handleTransactionEnd('Token withdraw successfully!');
            }
        }
    }, [willCheck, isWithdrawSuccess, withdrawError, withdrawReceiptError]);

    if (!isOpen) return null;

    const isLoading = isWithdrawLoading || isWithdrawPending;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Withdraw Staked NRG<span className="close-modul-button" onClick={onClose}>X</span></h2>
                <div className="wallet-info">
                    <img className="user-icon" src={ManLogo}></img>
                    <div className="ref-code">
                        <span>Ref link #</span>
                        <span>{shortAddress} <button onClick={copyLink} className='copy-btn'>Copy</button></span>
                    </div>
                    <div className="tokens">
                        <span>Tokens</span>
                        <span>{totalStakeK} NRG</span>
                    </div>
                    <div className="input-group">
                        <input
                            type="number"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => handleInput(e.target.value)}
                        />
                        <button onClick={handleMaxClick} className="max-btn">Max</button>
                    </div>
                    <button disabled={isLoading || isWithdraw} onClick={handleSubmit} className="daily-withdraw-btn">Withdraw</button>
                    <div className="days-remaining">Days Remaining: {remainingDays}</div>
                    <p className="withdraw-info">Min withdraw is 41675 NRG. You can withdraw in multiples of 41675 NRG</p>
                </div>
                {showAlert && (
                    <CustomAlert
                        message={alertMessage}
                        explorerLink={explorerLink}
                        onClose={closeCustomAlert}
                    />
                )}
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner">
                            <img src={loadingSpinner} alt="Loading..." />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WithdrawModal;
