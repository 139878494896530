// wagmi.js
import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { polygon } from 'wagmi/chains';

// Configuration for local development chain (if needed)
const localChain = {
  id: 1337,
  name: 'Localhost',
  iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Localhost',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['http://127.0.0.1:7545'] },
  },
  blockExplorers: {
    default: { name: 'Localhost Explorer', url: 'http://127.0.0.1:7545' },
  },
  testnet: true,
};

export const config = getDefaultConfig({
  appName: 'Your App Name',
  projectId: 'YOUR_PROJECT_ID',
  chains: [
    polygon
  ],
  ssr: false,
});