import React, { useState, useContext, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import ManLogo from '../assets/MaleLogo.png';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import '../styles/WithdrawCommition.css';

const WithdrawCommition = ({ isOpen, onClose }) => {
    const { address, isConnected } = useAccount();
    const { usdtDecimal, stakingContract } = useContext(WalletContext);
    const [amount, setAmount] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [explorerLink, setExplorerLink] = useState('');
    const [commition, setCommition] = useState(0);
    const [willCheck, setWillCheck] = useState(false);

    // Read commission using wagmi
    const { data: userCommition } = useReadContract({
        ...stakingContract,
        functionName: 'commition',
        args: [address],
        enabled: Boolean(address)
    });

    // Write contract setup using wagmi
    const {
        writeContract: withdrawCommitionReward,
        data: withdrawCommitionRewardData,
        error: withdrawCommitionRewardError,
        isPending: isWithdrawCommitionRewardPending
    } = useWriteContract();

    // Transaction Receipts
    const {
        isLoading: isWithdrawCommitionRewardLoading,
        isSuccess: isWithdrawCommitionRewardSuccess,
        error: withdrawCommitionRewardReceiptError,
    } = useWaitForTransactionReceipt({ hash: withdrawCommitionRewardData });

    const showCustomAlert = (message, explorerLink = '') => {
        setAlertMessage(message);
        setExplorerLink(explorerLink);
        setShowAlert(true);
    };

    const closeCustomAlert = () => {
        setShowAlert(false);
    };

    // Transaction Status Handlers
    const handleTransactionStart = () => {
        setWillCheck(true);
        console.log('Transaction started..');
    };

    // Helper function to extract error message
    const getErrorMessage = (error) => {
        if (!error) return 'Unknown error occurred';

        // Check for user rejection message in different parts of the error object
        const errorDetails = error.details || '';
        const errorMessage = error.message || '';
        const shortMessage = error.shortMessage || '';

        // Common user rejection messages
        if (errorDetails.includes('User denied transaction signature') ||
            errorMessage.includes('User denied transaction signature') ||
            shortMessage.includes('User denied transaction signature')) {
            return 'Transaction was rejected by user';
        }

        // Check for specific error types
        if (error.name === 'ContractFunctionExecutionError') {
            // Extract the Details field from the error
            const detailsMatch = error.message.match(/Details: (.*?)(?=\n|$)/);
            if (detailsMatch) {
                return detailsMatch[1];
            }
        }

        // Gas related errors
        if (errorMessage.includes('insufficient funds')) {
            return 'Insufficient funds for gas fee';
        }

        // Network related errors
        if (errorMessage.includes('network') || errorMessage.includes('disconnected')) {
            return 'Network connection error. Please check your connection';
        }

        // Contract related errors
        if (errorMessage.includes('execution reverted')) {
            const revertMatch = errorMessage.match(/execution reverted:(.*?)(?=\n|$)/);
            if (revertMatch) {
                return revertMatch[1].trim();
            }
            return 'Transaction failed during contract execution';
        }

        // If no specific error is found, return the original message or a default
        return error.message || 'Transaction failed. Please try again';
    };

    // Update the handleTransactionEnd to use the new error handling
    const handleTransactionEnd = (text, transactionHash, error) => {

        if (error) {
            const errorMessage = getErrorMessage(error);
            showCustomAlert(errorMessage);
            setWillCheck(false);
            return;
        }

        if (text && transactionHash) {
            const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
            showCustomAlert(`${text}`, explorerLink);
        } else if (text) {
            showCustomAlert(text);
        }
        setWillCheck(false);
    };

    const updateCommition = async () => {
        if (!isConnected) {
            return;
        }
        try {
            const commitionNumber = Number(userCommition);
            const commitionInDecimal = commitionNumber / (10 ** usdtDecimal);
            if (commitionInDecimal > 0) {
                setCommition(commitionInDecimal.toFixed(2));
            }
            console.log('Commition updated.', commitionInDecimal.toFixed(2));
        } catch (error) {
            console.error('Error updating commition', error);
        }
    }

    const handleMaxClick = async () => {
        if (!stakingContract) {
            setAmount(0);
            return;
        }
        try {
            console.log('Going to set withdraw amount.');
            setAmount(commition);
        } catch (error) {
            console.error('Error to update pendong reward', error);
        }
    };

    function shortenWalletAddress(address) {
        if (!address) return '';
        const start = address.slice(0, 6);
        const end = address.slice(-4);
        return `${start}***${end}`;
    }

    const referralURL = address ? `https://enter.impactxpro.com/referral/${address}` : '';
    const shortAddress = shortenWalletAddress(address);

    const copyLink = () => {
        if (referralURL) {
            navigator.clipboard.writeText(referralURL)
                .then(() => {
                    console.log('Referral URL copied to clipboard');
                    showCustomAlert('Referral URL copied!');
                })
                .catch((err) => {
                    console.error('Failed to copy referral URL: ', err);
                });
        } else {
            console.error('No referral URL available');
        }
    };

    const withdrawCommition = async () => {
        if (amount < 1667) {
            console.log("Commition Reward", amount);
            showCustomAlert('Wrong amount to claim!');
            return;
        }
        let commitionToWithdraw = 0;
        if (commitionToWithdraw == 0 && amount > 0) {
            commitionToWithdraw = (amount * (10 ** usdtDecimal)).toFixed(0);
        }
        try {
            if (commitionToWithdraw > 0) {
                handleTransactionStart();
                withdrawCommitionReward({
                    ...stakingContract,
                    functionName: 'withdrawCommition',
                    args: [commitionToWithdraw]
                });
            }
        } catch (err) {
            console.error("Commission withdraw failed:", err);
            handleTransactionEnd('Commission withdraw failed.');
            return;
        }
    };

    useEffect(() => {
        if (!isConnected) {
            return;
        }
        const intervalId = setInterval(() => {
            updateCommition();
        }, 1000);
        return () => clearInterval(intervalId);
    }, [isConnected, updateCommition]);

    // Effects
    useEffect(() => {
        if (willCheck) {
            if (withdrawCommitionRewardError || withdrawCommitionRewardReceiptError) {
                handleTransactionEnd(null, null, withdrawCommitionRewardError || withdrawCommitionRewardReceiptError);
            }

            if (isWithdrawCommitionRewardSuccess) {
                handleTransactionEnd('Referral reward withdraw successfully!');
            }
        }
    }, [willCheck, isWithdrawCommitionRewardSuccess, withdrawCommitionRewardError, withdrawCommitionRewardReceiptError]);

    const isLoading = isWithdrawCommitionRewardLoading || isWithdrawCommitionRewardPending;

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Withdraw Referral Reward<span className="close-modul-button" onClick={onClose}>X</span></h2>
                <div className="wallet-info">
                    <img className="user-icon" src={ManLogo}></img>
                    <div className="ref-code">
                        <span>Ref link #</span>
                        <span>{shortAddress} <button onClick={copyLink} className='copy-btn'>Copy</button></span>
                    </div>
                    <div className="tokens">
                        <span>Referral Reward</span>
                        <span>{commition} NRG</span>
                    </div>
                    <div className="input-group">
                        <input
                            type="number"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                        <button onClick={handleMaxClick} className="max-btn">Max</button>
                    </div>
                    <button onClick={withdrawCommition} className="daily-deposit-btn">Withdraw</button>
                    <p className="deposit-info">Min withdraw is 16670 NRG. You can withdraw in multiples of 16670 NRG. Withdraw fee 3334 NRG</p>
                </div>
                {showAlert && (
                    <CustomAlert
                        message={alertMessage}
                        explorerLink={explorerLink}
                        onClose={closeCustomAlert}
                    />
                )}
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner">
                            <img src={loadingSpinner} alt="Loading..." />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WithdrawCommition;
