import { React, useState, useEffect, useContext } from 'react';
import Sidebar from './Sidebar';
import MobileSidebar from './MobileSidebar';
import Header from './Header';
import Dashboard from './Dashbord';
import MembershipCards from './VIPMembershipCards';
import TransactionHistory from './Transactions';
import News from './News';
import VideoTutorials from './Video';
import ReferralAndMilestone from './ReferralAndMilestone';
import ContactForm from './ContactForm';
import AskQuickQuestion from './AskQuickQuestion';
import TermsAndConditions from './TermsAndConditions';
import AdsForm from './AdsForm';
import { useAccount, useReadContract } from 'wagmi';
import { WalletContext } from '../WalletContext';
import '../styles/Interface.css';
import YourMembership from './YourMembership';
import ServiceDApp from './ServiceDApp';

function Interface() {
    const { address, isConnected } = useAccount();
    const { contract } = useContext(WalletContext);
    const [selectedItem, setSelectedItem] = useState('Dashboard');
    const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= 850);

    const { data: isMember } = useReadContract({
        ...contract,
        functionName: 'isAMamber',
        args: [address],
        enabled: !!address
    });

    const isImpactXMember = () => {
        if (isConnected && contract) {
            if (isMember) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth <= 1030);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="app" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {(!isMobileScreen && isImpactXMember()) && (
                <div className='WideScreen' style={{ display: 'flex', flex: 1 }}>
                    <Sidebar onItemClick={handleItemClick} />
                    <div className="main-content" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Header />
                        <div className='main-body'>
                            <div className='under-main-body' style={{ flex: 1 }}>
                                <div className='page-grid-span'> {selectedItem === 'Dashboard' && <Dashboard />} </div>
                                <div className='page-grid-span'> {selectedItem === 'Nft Store' && <MembershipCards />} </div>
                                <div className='page-grid-span'> {selectedItem === 'My NFTs' && <YourMembership />} </div>
                                <div className='page-grid-span'> {selectedItem === 'Service & DApp' && <ServiceDApp />} </div>
                                <div className='page-grid-span'> {selectedItem === 'Transaction History' && <TransactionHistory />} </div>
                                <div className='page-grid-span'> {selectedItem === 'News' && <News />} </div>
                                <div className='page-grid-span'> {selectedItem === 'Video Tutorials' && <VideoTutorials />} </div>
                                <div className='page-grid-span'> {selectedItem === 'Team & Milestone' && <ReferralAndMilestone />} </div>
                                <div className='page-grid-span'> {selectedItem === 'Telegram Support' && <ContactForm />} </div>
                                <div className='page-grid-span'> {selectedItem === 'FAQ' && <AskQuickQuestion />} </div>
                                <div className='page-grid-span'> {selectedItem === 'Terms & Conditions' && <TermsAndConditions />} </div>
                                <div className='page-grid-span'> {selectedItem === 'Ads & Promotion' && <AdsForm />} </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {(isMobileScreen && isImpactXMember()) && (
                <div className='MobileScreen' style={{ display: 'flex', flex: 1 }}>
                    <MobileSidebar onItemClick={handleItemClick} />
                    <div className="main-content" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Header />
                        <div style={{ flex: 1 }}>
                            <div className='page-grid-span'> {selectedItem === 'Dashboard' && <Dashboard />} </div>
                            <div className='page-grid-span'> {selectedItem === 'Nft Store' && <MembershipCards />} </div>
                            <div className='page-grid-span'> {selectedItem === 'My NFTs' && <YourMembership />} </div>
                            <div className='page-grid-span'> {selectedItem === 'Service & DApp' && <ServiceDApp />} </div>
                            <div className='page-grid-span'> {selectedItem === 'Transaction History' && <TransactionHistory />} </div>
                            <div className='page-grid-span'> {selectedItem === 'News' && <News />} </div>
                            <div className='page-grid-span'> {selectedItem === 'Video Tutorials' && <VideoTutorials />} </div>
                            <div className='page-grid-span'> {selectedItem === 'Team & Milestone' && <ReferralAndMilestone />} </div>
                            <div className='page-grid-span'> {selectedItem === 'Telegram Support' && <ContactForm />} </div>
                            <div className='page-grid-span'> {selectedItem === 'FAQ' && <AskQuickQuestion />} </div>
                            <div className='page-grid-span'> {selectedItem === 'Terms & Conditions' && <TermsAndConditions />} </div>
                            <div className='page-grid-span'> {selectedItem === 'Ads & Promotion' && <AdsForm />} </div>
                        </div>
                    </div>
                </div>
            )}

            {(!isMobileScreen && !isImpactXMember()) && (
                <div className='WideScreen' style={{ display: 'flex', flex: 1 }}>
                    <div className="main-content" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Header />
                        <MembershipCards />
                    </div>
                </div>
            )}

            {(isMobileScreen && !isImpactXMember()) && (
                <div className='MobileScreen' style={{ display: 'flex', flex: 1 }}>
                    <div className="main-content" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Header />
                        <MembershipCards />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Interface;
