import React from 'react';
import image1 from '../assets/dapp/1.jpg';
import image2 from '../assets/dapp/2.jpg';
import image3 from '../assets/dapp/3.jpg';
import image4 from '../assets/dapp/4.jpg';
import image5 from '../assets/dapp/5.jpg';
import image6 from '../assets/dapp/6.jpg';
import image7 from '../assets/dapp/7.jpg';
import image8 from '../assets/dapp/8.jpg';
import image9 from '../assets/dapp/9.jpg';
import image10 from '../assets/dapp/10.jpg';
import image11 from '../assets/dapp/11.jpg';
import image12 from '../assets/dapp/12.jpg';
import image13 from '../assets/dapp/13.jpg';
import image14 from '../assets/dapp/14.jpg';
import image15 from '../assets/dapp/15.jpg';
import { WalletContext } from '../WalletContext';
import { useAccount, useReadContract, useReadContracts } from 'wagmi';
import '../styles/DecentralizedApps.css';

const DecentralizedApps = () => {
  return (
    <div className="decentralized-apps">
      <div className="app-grid">
        <div className="app-card">
          <img src={image1} alt="Blockchain and AI" />
          <div className="h3-p">
            <h3>Blockchain and AI Consultancy</h3>
            <p>We offer tailored consultancy, integrating AI and Blockchain for operational optimization and business growth.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
        <div className="app-card">
          <img src={image2} alt="Esports Tournaments" />
          <div className="h3-p">
            <h3>Esports Tournaments</h3>
            <p>Level up with esports tournaments at IMPACTX centers, fueled by IMTX tokens. Join gamers for competitive fun and rewards, all powered by blockchain technology.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
        <div className="app-card">
          <img src={image3} alt="Emerging Tech Education" />
          <div className="h3-p">
            <h3>Emerging Tech Education</h3>
            <p>At IMPACTX centers, dive into the future with our emerging tech education. Explore AI, blockchain, and more, all taught by industry experts for hands-on learning and real-world skills.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
      </div>
      <div className="app-grid">
        <div className="app-card">
          <img src={image4} alt="ChainTrack Pro" />
          <div className="h3-p">
            <h3>ChainTrack Pro</h3>
            <p>Seamlessly trace products from origin to destination with ChainTrack Pro. IMTX tokens power this supply chain solution, ensuring transparency and efficiency.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
        <div className="app-card">
          <img src={image5} alt="FlavorFunds" />
          <div className="h3-p">
            <h3>FlavorFunds</h3>
            <p>Share your taste, earn rewards with FlavorFunds. IMTX tokens incentivize feedback on products, creating a community-driven marketplace for taste exploration.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
        <div className="app-card">
          <img src={image6} alt="SoundScape NFTs" />
          <div className="h3-p">
            <h3>SoundScape NFTs</h3>
            <p>Explore the world of audio NFTs with SoundScape. Creators mint unique tokens on Solana, while users engage, collect, and trade exclusive audio experiences with IMTX tokens.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
      </div>
      <div className="app-grid">
        <div className="app-card">
          <img src={image7} alt="LearnVantage" />
          <div className="h3-p">
            <h3>LearnVantage</h3>
            <p>Unlock knowledge, earn rewards with LearnVantage. IMTX tokens drive this educational platform, offering interactive courses and incentives for learning.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
        <div className="app-card">
          <img src={image8} alt="StakeWave" />
          <div className="h3-p">
            <h3>StakeWave</h3>
            <p>Secure the future, shape the ecosystem with StakeWave. IMTX staking and governance dApp empower users to earn rewards and participate in network decisions.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
        <div className="app-card">
          <img src={image9} alt="PlayParks" />
          <div className="h3-p">
            <h3>PlayParks</h3>
            <p>Game, earn, repeat with PlayParks. IMTX-powered gaming dApp rewards players for skill, dedication, and engagement in a world of immersive experiences.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
      </div>
      <div className="app-grid">
        <div className="app-card">
          <img src={image10} alt="EcoQuest Adventures" />
          <div className="h3-p">
            <h3>EcoQuest Adventures</h3>
            <p>Embark on a journey to earn with EcoQuest Adventures. IMTX tokens fuel this play-to-earn game, where players explore, conquer challenges, and reap rewards.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
        <div className="app-card">
          <img src={image11} alt="CoinTrack360" />
          <div className="h3-p">
            <h3>CoinTrack360</h3>
            <p>Stay ahead of the curve with CoinTrack360. IMTX token integration provides real-time cryptocurrency portfolio tracking and insights for savvy investors.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
        <div className="app-card">
          <img src={image12} alt="RemitTokens" />
          <div className="h3-p">
            <h3>RemitTokens</h3>
            <p>Send funds globally, earn with RemitTokens. IMTX-enabled remittance dApp offers fast, lowcost transfers and rewards for cross-border transactions.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
      </div>
      <div className="app-grid">
        <div className="app-card">
          <img src={image13} alt="PropTokenize" />
          <div className="h3-p">
            <h3>PropTokenize</h3>
            <p>Fractional ownership made easy. IMTX tokens enable property tokenization, unlocking liquidity and diversification in real estate.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
        <div className="app-card">
          <img src={image14} alt="IdentityGuard Registry" />
          <div className="h3-p">
            <h3>IdentityGuard Registry</h3>
            <p>Secure land, verify identity with LandGuard ID. IMTX tokens enable transparent land registration and identity verification on the blockchain, ensuring authenticity in property transactions.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
        <div className="app-card">
          <img src={image15} alt="PowerPulse Exchange" />
          <div className="h3-p">
            <h3>PowerPulse Exchange</h3>
            <p>Energize your assets, trade sustainably with PowerPulse Exchange. IMTX tokens facilitate peer-to-peer energy trading and renewable energy certificate exchange on Solana's blockchain.</p>
          </div>
          <button className='comming-soon-button'>Coming Soon</button>
        </div>
      </div>
    </div>
  );
};

export default DecentralizedApps;