// components/Header.js
import { React, useEffect, useContext, useCallback, useState } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Data from './Data';
import logo from '../assets/whitelogo.png';
import { WalletContext } from '../WalletContext';
import { useAccount, useReadContract, useReadContracts } from 'wagmi';
import '../styles/Header.css';

const Header = () => {
  const { address, isConnected } = useAccount();
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const {
    referee, setReferee, setAllowanceData,
    web3, contract, setApproved, bitcoinExplorerMember, web3Token,
    setBitcoinExplorerMember, blockchainProMember, setBlockchainProMember, metaverseMasterMember,
    setMetaverseMasterMember, aiUltimateProMember, setAiUltimateProMember,
    contractAddress, timeDateContract, setJoiningDate, tokenAddress, usdtDecimal, setUsdtDecimal,
    nftTokenAddress, setIsAMember, nftContract, setPendingReward, stakingAddress,
    usdtContract, setMilestonRewards, nftMasterAddress, setTotalRefferals, connectedChainId,
    setConnectedChainId, setThisMonthEarning, setTodaysEarning, setTotalEarning,
    setThisYearEarning, timeDateAddress, stakingContract
  } = useContext(WalletContext);

  const { data: tokenBalance } = useReadContract({
    ...usdtContract,
    functionName: 'balanceOf',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: tokenDecimal } = useReadContract({
    ...usdtContract,
    functionName: 'decimals'
  });

  const { data: stakingTime } = useReadContract({
    ...stakingContract,
    functionName: 'stakingTime',
    args: [address],
    enabled: !!address
  });

  const { data: depositedTokens } = useReadContract({
    ...stakingContract,
    functionName: 'depositedTokens',
    args: [address],
    enabled: !!address
  });

  const { data: pendingRewards } = useReadContract({
    ...stakingContract,
    functionName: 'getPendingReward',
    args: [address],
    enabled: !!address
  });

  const { data: dailyReward } = useReadContract({
    ...stakingContract,
    functionName: 'getDailyReward',
    args: [address],
    enabled: !!address
  });

  const { data: rewardData } = useReadContract({
    ...stakingContract,
    functionName: 'getRewardData',
    args: [address],
    enabled: !!address
  });

  const { data: isMember } = useReadContract({
    ...contract,
    functionName: 'isAMamber',
    args: [address],
    enabled: !!address
  });

  const { data: levels } = useReadContract({
    ...contract,
    functionName: 'getLevels',
    args: [address],
    enabled: !!address
  });

  const { data: referrerLength } = useReadContract({
    ...contract,
    functionName: 'getReferrerLenth',
    args: [address],
    enabled: !!address
  });

  const { data: todaysEarning } = useReadContract({
    ...contract,
    functionName: 'getTodaysEarning',
    args: [address],
    enabled: !!address
  });

  const { data: monthEarning } = useReadContract({
    ...contract,
    functionName: 'getThisMonthEarning',
    args: [address],
    enabled: !!address
  });

  const { data: yearEarning } = useReadContract({
    ...contract,
    functionName: 'getThisYearEarning',
    args: [address],
    enabled: !!address
  });

  const { data: totalEarning } = useReadContract({
    ...contract,
    functionName: 'getTotalEarning',
    args: [address],
    enabled: !!address
  });

  const { data: milestonAmount } = useReadContract({
    ...contract,
    functionName: 'getMilestonAmount',
    args: [address],
    enabled: !!address
  });

  const { data: taxNumbers } = useReadContract({
    ...timeDateContract,
    functionName: 'getTaxNumbers',
    args: [address],
    enabled: !!address
  });

  const { data: taxData } = useReadContracts({
    contracts: taxNumbers?.map((taxNumber) => ({
      ...timeDateContract,
      functionName: 'getTaxData',
      args: [taxNumber]
    })) ?? [],
    enabled: !!taxNumbers?.length
  });

  const { data: allowance } = useReadContract({
    ...usdtContract,
    functionName: 'allowance',
    args: [address, contractAddress],
    enabled: !!address
  });

  const { data: businessRewardAmount } = useReadContract({
    ...stakingContract,
    functionName: 'rewardForBusness',
    args: [address],
    enabled: !!address
  });

  useEffect(() => {
    const pathParts = window.location.pathname.split('/');
    const referralIndex = pathParts.indexOf('referral');

    if (isConnected && isMember) { setIsAMember(isMember); };

    if (referralIndex !== -1 && pathParts.length > referralIndex + 1) {
      const referralAddress = pathParts[referralIndex + 1];
      setReferee(referralAddress);
      console.log(`Referred by:`, referralAddress);
    } else {
      setReferee('Unknown User');
    }
    console.log("Referraed by:", referee);
  }, [setReferee, referee]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    const intervalId = setInterval(() => {
      console.log('Pending reward:', pendingRewards);
      if (pendingRewards) {
        const rewards = Number(pendingRewards) / (10 ** Number(tokenDecimal));
        setPendingReward(rewards.toFixed(2));
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const updateDecimals = useCallback(() => {
    try {
      console.log('Updating decimal.');
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      setUsdtDecimal(decimalNumber);
    } catch (error) {
      console.error('Error updating usdt decimals', error);
    }
  }, [isConnected, usdtContract, setUsdtDecimal]);

  const updateReferral = useCallback(async () => {
    if (!contract || !address) {
      console.log('Contract or address not available');
      return;
    }
    try {
      console.log("Updating referrals...");
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      setUsdtDecimal(decimalNumber);
      const referalsTotal = referrerLength.toString();
      const erningTodays = todaysEarning.toString() / (10 ** decimalNumber);
      const erningThisMonth = monthEarning.toString() / (10 ** decimalNumber);
      const erningThisYear = yearEarning.toString() / (10 ** decimalNumber);
      const total_Erning = totalEarning.toString() / (10 ** decimalNumber);
      setTotalRefferals(referalsTotal);
      setTodaysEarning(erningTodays.toFixed(2));
      setThisMonthEarning(erningThisMonth.toFixed(2));
      setThisYearEarning(erningThisYear.toFixed(2));
      setTotalEarning(total_Erning.toFixed(2));
      console.log("Updating referrals sucsessfuly.", erningTodays, erningThisMonth, erningThisYear, total_Erning);
    } catch (error) {
      console.error("Error updating referrals", error);
    }
  }, [contract, address, setThisMonthEarning, setThisYearEarning, setTodaysEarning, setTotalEarning, setTotalRefferals]);

  const updateMilestoneReward = useCallback(async () => {
    console.log("Updating milestone reward...");

    try {
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      setUsdtDecimal(decimalNumber);
      console.log("Raw milestone amount:", milestonAmount);
      const rewardsAmountNumber = Number(milestonAmount.toString());
      const amount = (rewardsAmountNumber / (10 ** decimalNumber)).toFixed(2);
      console.log("Formatted milestone amount:", amount);
      setMilestonRewards(amount);
    } catch (error) {
      console.error("Error updating milestone amount", error);
      console.log("Contract address:", contract._address);
      console.log("Account:", address);
    }
  }, [contract, address, setMilestonRewards]);

  // Initialize and update all necessary data
  useEffect(() => {
    if (!isConnected) return;
    const initializeData = async () => {
      await updateMilestoneReward();
      await updateReferral();
    };
    initializeData();
  }, [isConnected, updateDecimals, updateMilestoneReward, updateReferral]);

  const checkMembership = useCallback(async () => {
    try {
      console.log(`Checking membership for address: ${address}`);

      // Get all owned tokens for the address
      const ownedTokens = await nftContract.methods.getOwnedTokens(address).call();
      console.log(`Owned Tokens: ${ownedTokens}`);

      let bitcoinExplorer = 0;
      let blockchainPro = 0;
      let metaverseMaster = 0;
      let aiUltimatePro = 0;

      // Iterate through owned tokens
      for (let tokenId of ownedTokens) {
        const character = await nftContract.methods.characterOf(tokenId).call();

        switch (parseInt(character)) {
          case 1:
            bitcoinExplorer++;
            break;
          case 2:
            blockchainPro++;
            break;
          case 3:
            metaverseMaster++;
            break;
          case 4:
            aiUltimatePro++;
            break;
          default:
            console.warn(`Unknown character type for token ${tokenId}: ${character}`);
        }
      }

      console.log(`Bitcoin Explorer: ${bitcoinExplorer}`);
      console.log(`Blockchain Pro: ${blockchainPro}`);
      console.log(`Metaverse Master: ${metaverseMaster}`);
      console.log(`AI Ultimate Pro: ${aiUltimatePro}`);

      setBitcoinExplorerMember(bitcoinExplorer);
      setBlockchainProMember(blockchainPro);
      setMetaverseMasterMember(metaverseMaster);
      setAiUltimateProMember(aiUltimatePro);

    } catch (error) {
      console.error('Error checking membership:', error);
    }
  }, [nftContract, address, setBitcoinExplorerMember, setBlockchainProMember, setMetaverseMasterMember, setAiUltimateProMember]);

  useEffect(() => {
    if (isConnected && web3 && nftContract && address) {
      checkMembership();
    }
  }, [isConnected, web3, nftContract, address, checkMembership]);

  useEffect(() => {
    if (isConnected && contract && address) {
      updateMilestoneReward();
      updateReferral();
    }
  }, [isConnected, contract, address, updateMilestoneReward, updateReferral]);

  useEffect(() => {
    setShowVideoPopup(true);
  }, []);

  useEffect(() => {
    if (isConnected) {
      console.log('Account Address:', address);
      console.log('BitcoinExplorerMembership:', bitcoinExplorerMember);
      console.log('BlockchainProMembership:', blockchainProMember);
      console.log('MetaverseMasterMembership:', metaverseMasterMember);
      console.log('AiUltimateProMembership:', aiUltimateProMember);
    }
  }, [isConnected, address, bitcoinExplorerMember, blockchainProMember, metaverseMasterMember, aiUltimateProMember]);

  const VideoPopup = ({ onCloseVideo }) => {
    return (
      <div className="video-popup-overlay">
        <div className="video-popup">
          <button className="popup-close-btn" onClick={onCloseVideo}>✕</button>
          <div className='video-popup-container'>
            <iframe className='popup-video popup-video-bacground' src={isMember ? 'https://player.vimeo.com/video/1042885806' : 'https://player.vimeo.com/video/1042857036'} frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="banner"></iframe>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="header">
      {showVideoPopup && (
        <VideoPopup
          onCloseVideo={() => setShowVideoPopup(false)}
        />
      )}
      <div className="header-left">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <ConnectButton />
    </div>
  );
};

export default Header;