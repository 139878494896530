import React, { useState, useEffect, useRef } from 'react';
import '../styles/Sidebar.css';

const Sidebar = ({ onItemClick }) => {
  const [selectedItem, setSelectedItem] = useState('Dashboard');

  const handleItemClick = (item) => {
    if (item === 'Nft Marketplace') {
      window.location.href = 'https://marketplace.impactxpro.com';
    }
    setSelectedItem(item);
    onItemClick(item);
  };

  const sidebarItems = [
    { name: 'Dashboard', icon: 'fas fa-tachometer-alt' },
    { name: 'Nft Store', icon: 'fas fa-id-card' },
    { name: 'My NFTs', icon: 'fas fa-user-plus' },
    { name: 'Service & DApp', icon: 'fas fa-app-store' },
    { name: 'Nft Marketplace', icon: 'fas fa-id-card' },
    { name: 'Transaction History', icon: 'fas fa-history' },
    { name: 'News', icon: 'fas fa-newspaper' },
    { name: 'Video Tutorials', icon: 'fas fa-video' },
    { name: 'Team & Milestone', icon: 'fas fa-user-plus' },
    { name: 'Ads & Promotion', icon: 'fas fa-ad' },
    { name: 'FAQ', icon: 'fas fa-question-circle' },
    { name: 'Terms & Conditions', icon: 'fas fa-file-contract' },
    { name: 'Telegram Support', icon: 'fas fa-headset support-item' },
  ];

  return (
    <>
      <div className="sidebar-container">
        <div className="sidebar">
          <div className="sidebar-content">
            {sidebarItems.map((item) => (
              <div
                key={item.name}
                className={`${item.name === 'Telegram Support' ? 'bottom-item' : 'sidebar-item'} ${selectedItem === item.name ? 'active' : ''}`}
                onClick={() => handleItemClick(item.name)}
              >
                <i className={item.icon}></i>
                <span className="item-name">{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
