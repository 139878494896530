import React, { useCallback, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import GaugeComponent from 'react-gauge-component';
import WithdrawModul from './WithdrawModul';
import DailyEarningModul from "./DailyEarningModul";
import GiftBoxPopup from './GiftBoxPopup';
import BennerAds from './BennerAds';
import AffiliateSections from './AffiliateSections';
import MilestoneRewards from './MilestoneRewards';
import Membership from './Membership';
import AllTimeStats from './AllTimeStats';
import InvestModul from './InvestModul';
import Web3 from 'web3';
import dataLabAbi from '../ABI/timeABI.json';
import { WalletContext } from '../WalletContext';
import NftImage from '../assets/cards/BitcoinExplorer.png';
import CustomAlert from './CustomAlert';
import BtcLogo from "../assets/crypto/btc-icon.png";
import EthLogo from "../assets/crypto/eth-icon.png";
import BnbLogo from "../assets/crypto/bnb-icon.png";
import BtcCashLogo from "../assets/crypto/bitcoin-cash-icon.png";
import DogeLogo from "../assets/crypto/doge-icon.png";
import SolLogo from "../assets/crypto/solana-icon.webp";
import XrpLogo from "../assets/crypto/xrp-icon.png";
import CardanoLogo from "../assets/crypto/cardano-icon.png";
import NrgLogo from "../assets/crypto/nrg-icon.png";
import SuiLogo from "../assets/crypto/sui-icon.png";
import AtomLogo from "../assets/crypto/ATOM.webp";
import UserLogo from "../assets/MaleLogo.png";
import { useAccount, useReadContract, useReadContracts } from 'wagmi';
import '../styles/Dashboard.css';
import UserProfile from './UserProfile';
import DashboardOverview from '../assets/tutorial/dashboard-overview.mp4';

function Dashboard() {
  const { address, isConnected } = useAccount();
  const [isInvestModulOpen, setInvestModulOpen] = useState(false);
  const [isProfileModulOpen, setProfileModulOpen] = useState(false);
  const [isWithdrawModulOpen, setWithdrawModulOpen] = useState(false);
  const [isGiftBoxOpen, setGiftBoxOpen] = useState(false);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [name, setName] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [videosLink, setVideosLink] = useState(null);
  const [dashbordVideo, setDashbordVideo] = useState();
  const [nrgPercentage, setNrgPercentage] = useState(0);
  const [commitionEarned, setCommitionEarned] = useState(0);
  const [nftValue, setNftValue] = useState(0);
  const [explorerLink, setExplorerLink] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isDailyModulOpen, setDailyModulOpen] = useState(false);
  const [profile, setProfile] = useState({
    image: UserLogo,
    userName: 'myname',
    status: 'Active',
    value: 0,
    teamMember: 0
  });
  const [allTokenPrice, setAllTokenPrices] = useState({
    ETH: 2616.09,
    BNB: 522.27,
    BTC: 58388.11,
    BCH: 330.09,
    DOGE: 0.13,
    SOL: 145.33,
    XRP: 0.45,
    ADA: 0.32,
    SUI: 0.32,
    ATOM: 0.32,
    NRG: 0.0003
  });

  const { timeDateAddress, totalStakeK, setTotalStakeK, totalStake, contract, setTotalStake, stakingContract,
    usdtContract, totalInvestedK, setTotalInvestedK, totalRefincomK, setTotalRefincomK, timeDateContract, nftValueUsdt, setNftValueUsdt
  } = useContext(WalletContext);

  const updateVideosUrl = useCallback(async () => {
    try {
      console.log(`Updating reward videos`);
      const web3Instance = new Web3('http://127.0.0.1:7545');
      const contractInstance = new web3Instance.eth.Contract(dataLabAbi, timeDateAddress);

      const videosNonce = await contractInstance.methods.videoNonce().call();
      const videosNonceNumber = Number(videosNonce.toString());
      let videoLink;
      let MetaverseMasterLink;

      for (let i = 1; i <= videosNonceNumber; i++) {
        const videosData = await contractInstance.methods.getVideoData(i).call();
        if (videosData.types === 'Dashboard Video') {
          videoLink = videosData.url;
        }
        if (videosData.types === 'Metaverse Master') {
          MetaverseMasterLink = videosData.url;
        }
      }
      setDashbordVideo(videoLink);
      setVideosLink({ MMLink: 'MetaverseMasterLink' });
      console.log('Reward videos updated:', videoLink);
    } catch (error) {
      console.error('Error updating reward videos:', error);
    }
  }, [timeDateAddress]);

  const showCustomAlert = (message, explorerLink = '') => {
    setAlertMessage(message);
    setExplorerLink(explorerLink);
    setShowAlert(true);
  };

  const closeCustomAlert = () => {
    setShowAlert(false);
  };

  // Contract Reads
  const { data: tokenDecimal } = useReadContract({
    ...usdtContract,
    functionName: 'decimals'
  });

  const { data: rewardForBusness } = useReadContract({
    ...stakingContract,
    functionName: 'rewardForBusness',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: totalStakeAmount } = useReadContract({
    ...stakingContract,
    functionName: 'depositedTokens',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: isFrozenUser } = useReadContract({
    ...contract,
    functionName: 'isFrozenUser',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: totalInvestedUsdt } = useReadContract({
    ...contract,
    functionName: 'totalInvested',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: totalreferralErning } = useReadContract({
    ...contract,
    functionName: 'totalRefIncome',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: l1LockReferReward } = useReadContract({
    ...contract,
    functionName: 'lockReferReward',
    args: [address, 1],
    enabled: Boolean(address)
  });

  const { data: l2LockReferReward } = useReadContract({
    ...contract,
    functionName: 'lockReferReward',
    args: [address, 2],
    enabled: Boolean(address)
  });

  const { data: l3LockReferReward } = useReadContract({
    ...contract,
    functionName: 'lockReferReward',
    args: [address, 3],
    enabled: Boolean(address)
  });

  const { data: l4LockReferReward } = useReadContract({
    ...contract,
    functionName: 'lockReferReward',
    args: [address, 4],
    enabled: Boolean(address)
  });

  const { data: l5LockReferReward } = useReadContract({
    ...contract,
    functionName: 'lockReferReward',
    args: [address, 5],
    enabled: Boolean(address)
  });

  const { data: totalActiveNode } = useReadContract({
    ...contract,
    functionName: 'totalNode',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: totalCommition } = useReadContract({
    ...stakingContract,
    functionName: 'commition',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: totalPendingRewards } = useReadContract({
    ...stakingContract,
    functionName: 'getPendingReward',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: referralData } = useReadContract({
    ...contract,
    functionName: 'getReferrals',
    args: [address],
    enabled: Boolean(address)
  });

  const { data: tokenPrice } = useReadContract({
    ...timeDateContract,
    functionName: 'getTokenPrice',
    enabled: Boolean(address)
  });

  const allLevels = [0, 1, 2, 3, 4];

  const { data: minAmountForRewards } = useReadContracts({
    contracts: allLevels?.map((each) => ({
      ...contract,
      functionName: 'minAmountForRewards',
      args: [each]
    })) ?? [],
    enabled: !!allLevels?.length
  });

  const getInFormate = (amount) => {
    if (Number(amount) > 0) {
      const theAmount = Number(amount) / (10 ** Number(tokenDecimal));

      if (theAmount <= 1e3) {
        return (theAmount.toFixed(2));
      } else if (theAmount > 1e3 && theAmount <= 1e6) {
        return (`${(theAmount / 1e3).toFixed(2)} K`);
      } else if (theAmount > 1e6 && theAmount <= 1e9) {
        return (`${(theAmount / 1e6).toFixed(2)} M`);
      } else if (theAmount > 1e9) {
        return (`${(theAmount / 1e9).toFixed(2)} B`);
      } else {
        return 0
      }
    } else {
      return 0;
    }
  }
  
  const getInFormateWithoutDecimal = (amount) => {
    if (Number(amount) > 0) {
      const theAmount = Number(amount) / (10 ** Number(tokenDecimal));

      if (theAmount <= 1e3) {
        return (theAmount.toFixed(0));
      } else if (theAmount > 1e3 && theAmount <= 1e6) {
        return (`${(theAmount / 1e3).toFixed(0)} K`);
      } else if (theAmount > 1e6 && theAmount <= 1e9) {
        return (`${(theAmount / 1e6).toFixed(0)} M`);
      } else if (theAmount > 1e9) {
        return (`${(theAmount / 1e9).toFixed(0)} B`);
      } else {
        return 0
      }
    } else {
      return 0;
    }
  }

  const getNftValue = () => {
    if (Number(totalInvestedUsdt) > 0) {
      const usdtAmount = Number(totalInvestedUsdt);
      const pendingNrg = Number(totalPendingRewards);
      const stakedNrg = Number(totalStakeAmount);
      const totalUsdt = ((pendingNrg + stakedNrg) / Number(tokenPrice)) + usdtAmount;
      return getInFormate(totalUsdt);
    } else {
      return 0;
    }
  }

  const getNrgValueInUsdt = (amount) => {
    if (amount > 0 && getNrgPrice() > 0) {
      
    }
  }

  const getIsFrozenUser = () => {
    if (isConnected && contract) {
      if (isFrozenUser) {
        return 'Inactive';
      } else {
        return 'Active';
      }
    } else {
      return 'Connect Wallet';
    }
  }

  const getNrgPercentage = () => {
    if (totalActiveNode) {
      if (Number(totalActiveNode) > 0) {
        return Number(totalActiveNode) * 10;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const getTotalStakeAmount = () => {
    if (totalStakeAmount) {
      if (Number(totalStakeAmount) > 0) {
        return getInFormate(Number(totalStakeAmount));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const getTotalInvestedUsdt = () => {
    if (totalInvestedUsdt) {
      if (Number(totalInvestedUsdt) > 0) {
        return getInFormate(Number(totalInvestedUsdt));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const getTotalreferralErning = () => {
    if (totalreferralErning) {
      if (Number(totalreferralErning) > 0) {
        return getInFormate(Number(totalreferralErning));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const getLockReferralErning = () => {
    // console.log('Lock referral', l1LockReferReward, l2LockReferReward, l3LockReferReward, l4LockReferReward, l5LockReferReward);
    if (isConnected && address) {
      const totalLockAmount = Number(l1LockReferReward) + Number(l2LockReferReward) + Number(l3LockReferReward) + Number(l4LockReferReward) + Number(l5LockReferReward);
      if (totalLockAmount > 0) {
        return getInFormate(totalLockAmount);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const getLockReferralText = () => {
    // console.log('Lock referral', minAmountForRewards);
    if (isConnected && address && minAmountForRewards) {
      if (getLockReferralErning() > 0) {
        return `This amount will unlock after you complete ${Number(l1LockReferReward) > 0 ? 'L1' : ''} ${Number(l2LockReferReward) > 0 ? 'L2' : ''} ${Number(l3LockReferReward) > 0 ? 'L3' : ''} ${Number(l4LockReferReward) > 0 ? 'L4' : ''} ${Number(l5LockReferReward) > 0 ? 'L5' : ''}
        target (${Number(l1LockReferReward) > 0 ? `$${getInFormateWithoutDecimal(minAmountForRewards[0].result)}` : ''} ${Number(l2LockReferReward) > 0 ? `$${getInFormateWithoutDecimal(minAmountForRewards[1].result)}` : ''} ${Number(l3LockReferReward) > 0 ? `$${getInFormateWithoutDecimal(minAmountForRewards[2].result)}` : ''} ${Number(l4LockReferReward) > 0 ? `$${getInFormateWithoutDecimal(minAmountForRewards[3].result)}` : ''} ${Number(l5LockReferReward) > 0 ? `$${getInFormateWithoutDecimal(minAmountForRewards[4].result)}` : ''}).`;
      } else {
        return 'No referral reward locked.';
      }
    } else {
      return 0;
    }
  }

  const getAvailableReferralErning = () => {
    if (totalreferralErning) {
      if (Number(totalreferralErning) > 0) {
        const amount = Number(totalreferralErning) - getLockReferralErning();
        return getInFormate(Number(totalreferralErning));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const getTotalCommition = () => {
    if (totalCommition) {
      if (Number(totalCommition) > 0) {
        return getInFormate(Number(totalCommition));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const getPendingReward = () => {
    if (totalPendingRewards) {
      if (Number(totalPendingRewards) > 0) {
        return getInFormate(Number(totalPendingRewards));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const getTotalReferral = () => {
    if (isConnected && referralData) {
      if (referralData.length > 0) {
        return referralData.length;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const getNrgPrice = () => {
    if (isConnected && tokenPrice) {
      if (Number(tokenPrice) > 0) {
        return (1 / Number(tokenPrice)).toFixed(7);
      } else {
        return 0;
      }
    }
  }

  const handleDailyWithdraw = async () => {
    if (!isConnected) {
      showCustomAlert('Connect your wallet first.');
      return;
    }
    try {
      setDailyModulOpen(true);
    } catch (error) {
      console.error("Withdrawal module failed to open:", error);
    }
  };

  const handleInvestModul = () => {
    setInvestModulOpen(true);
  }

  const handleWithdrawModul = () => {
    setWithdrawModulOpen(true);
  }

  const handleCloseModul = () => {
    setInvestModulOpen(false);
  }

  function shortenWalletAddress(address) {
    if (!address) return '';
    const start = address.slice(0, 6);
    const end = address.slice(-4);
    return `${start}***${end}`;
  }

  const referralURL = address ? `https://enter.impactxpro.com/referral/${address}` : '';
  const shortAddress = shortenWalletAddress(address);

  const copyLink = () => {
    if (referralURL) {
      navigator.clipboard.writeText(referralURL)
        .then(() => {
          console.log('Referral URL copied to clipboard');
          showCustomAlert('Referral URL copied!');
        })
        .catch((err) => {
          console.error('Failed to copy referral URL: ', err);
        });
    } else {
      console.error('No referral URL available');
    }
  };

  async function getCurrentPrice(symbol) {
    try {
      const response = await axios.get(`https://api.binance.com/api/v3/ticker/price`, {
        params: { symbol },
      });
      return parseFloat(response.data.price);
    } catch (error) {
      console.error('Error fetching current price:', error);
      throw error;
    }
  }

  const getBtapPrice = async () => {
    try {
      const response = await axios.get('https://api.raydium.io/v1/prices/35buk6kyDUqi1vkw2a5LfJKW8ubAJGoqcVXTDHHePiT4');
      return response.data.price;
    } catch (error) {
      console.error('Error fetching the token price:', error);
      return 0;
    }
  };

  const fetchTokenPrices = async () => {
    try {
      const btcUsdPrice = await getCurrentPrice('BTCUSDT');
      const bnbUsdPrice = await getCurrentPrice('BNBUSDT');
      const ethUsdPrice = await getCurrentPrice('ETHUSDT');
      const xrpUsdPrice = await getCurrentPrice('XRPUSDT');
      const solUsdPrice = await getCurrentPrice('SOLUSDT');
      const dogeUsdPrice = await getCurrentPrice('DOGEUSDT');
      const bchUsdPrice = await getCurrentPrice('BCHUSDT');
      const adaUsdPrice = await getCurrentPrice('ADAUSDT');
      const atomUsdPrice = await getCurrentPrice('ATOMUSDT');
      const suiUsdPrice = await getCurrentPrice('SUIUSDT');
      const btapSolPrice = await getBtapPrice();
      // console.log('All Price', btapSolPrice);

      setAllTokenPrices({
        ETH: ethUsdPrice.toFixed(2),
        BNB: bnbUsdPrice.toFixed(2),
        BTC: btcUsdPrice.toFixed(2),
        XRP: xrpUsdPrice.toFixed(2),
        SOL: solUsdPrice.toFixed(2),
        DOGE: dogeUsdPrice.toFixed(2),
        BCH: bchUsdPrice.toFixed(2),
        ADA: adaUsdPrice.toFixed(2),
        ATOM: atomUsdPrice.toFixed(2),
        SUI: suiUsdPrice.toFixed(2),
        NRG: (1 / Number(tokenPrice)).toFixed(4)
      });
    } catch (error) {
      console.error('Error fetching token prices:', error);
    }
  };

  const VideoPopup = ({ video, onCloseVideo }) => {
    return (
      <div className="video-popup-overlay">
        <div className="video-popup">
          <button className="popup-close-btn" onClick={onCloseVideo}>✕</button>
          <video className='popup-video-bacground' src={video} controls autoPlay width="100%">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  };

  const getUserData = async (ethAddress) => {
    try {
      const response = await fetch(`https://impactxtech.com/uploads/data/${ethAddress}`);
      const data = await response.json();
      if (response.ok) {
        setName(data.name);
        setImageUrl(`https://impactxtech.com/flaskapp/uploads/${data.image}`);
        console.log('Fetched data:', data);
      } else {
        console.error(data.error || 'Failed to fetch data');
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  useEffect(() => {
    if (isConnected && address) {
      getUserData(address);
    }
  }, [isConnected, address, getUserData]);

  useEffect(() => {
    fetchTokenPrices();
    const intervalId = setInterval(fetchTokenPrices, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const cryptoData = [
    { name: 'NRG', price: `$ ${allTokenPrice.NRG}`, logo: NrgLogo },
    { name: 'ETH', price: `$ ${allTokenPrice.ETH}`, logo: EthLogo },
    { name: 'BNB', price: `$ ${allTokenPrice.BNB}`, logo: BnbLogo },
    { name: 'BTC', price: `$ ${allTokenPrice.BTC}`, logo: BtcLogo },
    { name: 'SOL', price: `$ ${allTokenPrice.SOL}`, logo: SolLogo },
    { name: 'XRP', price: `$ ${allTokenPrice.XRP}`, logo: XrpLogo },
    { name: 'DOGE', price: `$ ${allTokenPrice.DOGE}`, logo: DogeLogo },
    { name: 'ADA', price: `$ ${allTokenPrice.ADA}`, logo: CardanoLogo },
    { name: 'BCH', price: `$ ${allTokenPrice.BCH}`, logo: BtcCashLogo },
    { name: 'ATOM', price: `$ ${allTokenPrice.ATOM}`, logo: AtomLogo },
    { name: 'SUI', price: `$ ${allTokenPrice.SUI}`, logo: SuiLogo }
  ];

  return (
    <>
      <div className='dash'>
        <div className='dash-grid-span1'>
          <div className="staking-container">
            < BennerAds />
            <div className="stats-row">
              <div className="stat-item">
                <span className="stat-label">Staked NRG</span>
                <span className="stat-value">{getTotalStakeAmount()}</span>
              </div>
              <div className="stat-item">
                <span className="stat-label">Staked Value</span>
                <span className="stat-value">$ {getTotalInvestedUsdt()}</span>
              </div>
              <div className="stat-item">
                <span className="stat-label last-label">Total Referral Reward</span>
                <span className="stat-value last-label">$ {getTotalreferralErning()}</span>
                <span className="lock-amount">
                  <span className='locked-amount'>Locked: $ {getLockReferralErning()}</span>
                  <span className='fa fa-question-circle tooltip question-icon'>
                    <span class="tooltiptext">{getLockReferralText()}</span>
                  </span>
                </span>
              </div>
            </div>
            <div className="button-row">
              <button onClick={handleWithdrawModul} className="action-button unstake">Unstake</button>
              <button onClick={handleInvestModul} className="action-button add">Nft Store</button>
              <button onClick={handleDailyWithdraw} className="action-button boost">Boost</button>
            </div>

            <div className="ref-code">
              <span className='copy-referral-text'>Ref link #</span>
              <span>{shortAddress} <button onClick={copyLink} className='action-button copy-referral-button'>Copy</button></span>
            </div>

            <div className="metrics-grid">
              <div className="metric-card">
                <div className="metric-content">
                  <div>
                    <div className="metric-value">$ {getTotalCommition()}</div>
                    <div className="metric-label">REFERRAL EARNINGS</div>
                  </div>
                  <div className="dash-icon"><i className='fas fa-sack-dollar dash-icon'></i></div>
                </div>
              </div>

              <div className="metric-card">
                <div className="metric-content">
                  <div>
                    <div className="metric-value">{getPendingReward()} NRG</div>
                    <div className="metric-label">STAKE EARNINGS</div>
                  </div>
                  <div className="dash-icon"><i className='fas fa-sack-dollar dash-icon'></i></div>
                </div>
              </div>

              <div className="metric-card">
                <div className="metric-content">
                  <div>
                    <div className="metric-value">{getTotalReferral()}</div>
                    <div className="metric-label">MEMBERS REFERRED</div>
                  </div>
                  <div className="dash-icon"><i className='fas fa-user dash-icon'></i></div>
                </div>
              </div>

              <div className="metric-card">
                <div className="metric-content">
                  <div>
                    <div className="metric-value">{Number(totalActiveNode)}</div>
                    <div className="metric-label">ACTIVE NODES</div>
                  </div>
                  <div className="dash-icon"><i className="fas fa-dollar-sign dash-icon"></i></div>
                </div>
              </div>

              <div className="metric-card">
                <div className="metric-content">
                  <div>
                    <div className="metric-value">$ {getNftValue()}</div>
                    <div className="metric-label">NFT VALUE</div>
                  </div>
                  <div className="dash-icon"><i className='fas fa-sack-dollar dash-icon'></i></div>
                </div>
              </div>

              <div className="metric-card">
                <div className="metric-content">
                  <div>
                    <div className="metric-value">$ {getNrgPrice()}</div>
                    <div className="metric-label">NRG PRICE</div>
                  </div>
                  <div className="dash-icon"><i className='fas fa-sack-dollar dash-icon'></i></div>
                </div>
              </div>
            </div>
          </div>
          <div className="crypto-ticker">
            <div className="ticker-content">
              {cryptoData.map((crypto, index) => (
                <div key={index} className="crypto-item">
                  <img src={crypto.logo} alt={`${crypto.name} Logo`} className="crypto-logo" />
                  <span className='crypto-name'>{crypto.name}</span> <span className='crypto-price'>{crypto.price}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='dash-grid-span2'>
          <div className='gauge-component'>
            <GaugeComponent
              value={getNrgPercentage()}
              type="radial"
              labels={{
                tickLabels: {
                  type: "inner",
                  ticks: [
                    { value: 20 },
                    { value: 40 },
                    { value: 60 },
                    { value: 80 },
                    { value: 100 }
                  ]
                }
              }}
              arc={{
                colorArray: ['#EA4228', '#5BE12C'],
                subArcs: [{ limit: 10 }, { limit: 30 }, {}, {}, {}],
                padding: 0.02,
                width: 0.3
              }}
              pointer={{
                elastic: true,
                animationDelay: 0
              }}
            />
            <h6 className='nrg-level-text'>NRG LEVEL</h6>
          </div>
          <div className="profile-info">
            <div className="profile-info-header">
              <img className='profile-image' src={imageUrl !== null ? imageUrl : UserLogo}></img>
              <button onClick={(e) => setProfileModulOpen(true)} className='edit-button'>Edit</button>
            </div>
            <div className="dash-card-details">
              <div className="info-row">
                <span className="info-label">User Name:</span>
                <span className="info-value">{name ? name : 'ImpactXPro'}</span>
              </div>
              <div className="info-row">
                <span className="info-label">Status:</span>
                <span className="info-value">{getIsFrozenUser()}</span>
              </div>
              <div className="info-row">
                <span className="info-label">Profile Value:</span>
                <span className="info-value">$ {getNftValue()}</span>
              </div>
              <div className="info-row">
                <span className="info-label">Team Size:</span>
                <span className="info-value">{getTotalReferral()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      < GiftBoxPopup
        isOpen={isGiftBoxOpen}
        onClose={() => setGiftBoxOpen(false)}
      />
      <WithdrawModul
        isOpen={isWithdrawModulOpen}
        onClose={() => setWithdrawModulOpen(false)}
      />
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          explorerLink={explorerLink}
          onClose={closeCustomAlert}
        />
      )}
      <DailyEarningModul
        isOpen={isDailyModulOpen}
        onClose={() => setDailyModulOpen(false)}
      />
      <UserProfile
        isOpen={isProfileModulOpen}
        onClose={() => setProfileModulOpen(false)}
      />
      {showVideoPopup && (
        <VideoPopup
          video={DashboardOverview}
          onCloseVideo={() => setShowVideoPopup(false)}
        />
      )}

      {isInvestModulOpen && (
        <div className="modul-overlay">
          <div className="modul-container">
            <button
              className="modul-close-button"
              onClick={handleCloseModul}
              aria-label="Close investment modul"
            >
              ✕
            </button>
            <InvestModul
              isOpen={isInvestModulOpen}
              onClose={handleCloseModul}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;