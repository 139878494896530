import React, { useState, useEffect, useRef } from 'react';
import '../styles/MobileSidebar.css';

const MobileSidebar = ({ onItemClick }) => {
    const [selectedItem, setSelectedItem] = useState('Dashboard');
    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);
  
    const handleItemClick = (item) => {
      if (item === 'Nft Marketplace') {
        window.location.href = 'https://marketplace.impactxpro.com';
      }
      setSelectedItem(item);
      onItemClick(item);
      setIsOpen(false);
    };
  
    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };
  
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        console.log('Clicked outside sidebar');
        setIsOpen(false);
      } else {
        console.log('Clicked inside sidebar');
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

  const sidebarItems = [
    { name: 'Dashboard', icon: 'fas fa-tachometer-alt' },
    { name: 'Nft Store', icon: 'fas fa-id-card' },
    { name: 'My NFTs', icon: 'fas fa-user-plus' },
    { name: 'Service & DApp', icon: 'fas fa-app-store' },
    { name: 'Nft Marketplace', icon: 'fas fa-id-card' },
    { name: 'Transaction History', icon: 'fas fa-history' },
    { name: 'News', icon: 'fas fa-newspaper' },
    { name: 'Video Tutorials', icon: 'fas fa-video' },
    { name: 'Team & Milestone', icon: 'fas fa-user-plus' },
    { name: 'Ads & Promotion', icon: 'fas fa-ad' },
    { name: 'FAQ', icon: 'fas fa-question-circle' },
    { name: 'Terms & Conditions', icon: 'fas fa-file-contract' },
    { name: 'Telegram Support', icon: 'fas fa-headset support-item' },
  ];

  return (
    <>
      <button className="mobileSidebar-toggle" onClick={toggleSidebar}>
        <i className="fas fa-bars"></i>
      </button>
      <div ref={sidebarRef} className={`mobileSidebar-container ${isOpen ? '' : 'closed'}`}>
        <div className="mobileSidebar">
          <div className="mobileSidebar-content">
            {sidebarItems.map((item) => (
              <div
                key={item.name}
                className={`mobileSidebar-item ${selectedItem === item.name ? 'active' : ''}`}
                onClick={() => handleItemClick(item.name)}
              >
                <i className={item.icon}></i>
                <span className="item-name">{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileSidebar;
