import React, { useState, useContext, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import ManLogo from '../assets/MaleLogo.png';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import '../styles/PendingRewardModul.css';

const PendingRewardModul = ({ isOpen, onClose }) => {
    const { address, isConnected } = useAccount();
    const { stakingContract, usdtContract, web3Stake } = useContext(WalletContext);
    const [amount, setAmount] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [explorerLink, setExplorerLink] = useState('');
    const [pendingReward, setPendingReward] = useState(0);
    const [minimumClaimAmount, setMinimumClaimAmount] = useState(0);
    const [withdrawFeeAmount, setWithdrawFeeAmount] = useState(0);
    const [willCheck, setWillCheck] = useState(false);

    const { data: tokenDecimal } = useReadContract({
        ...usdtContract,
        functionName: 'decimals',
        enabled: Boolean(address)
    });

    const { data: getPendingReward } = useReadContract({
        ...stakingContract,
        functionName: 'getPendingReward',
        args: [address],
        enabled: Boolean(address)
    });

    const { data: minimumAmount } = useReadContract({
        ...stakingContract,
        functionName: 'minimumClaimAmount',
        enabled: Boolean(address)
    });

    const { data: withdrawFee } = useReadContract({
        ...stakingContract,
        functionName: 'withdrawFee',
        enabled: Boolean(address)
    });

    const {
        writeContract: writeClaim,
        data: claimData,
        error: claimError,
        isPending: isClaimPending
    } = useWriteContract();

    // Transaction Receipts
    const {
        isLoading: isClaimLoading,
        isSuccess: isClaimSuccess,
        error: claimReceiptError,
    } = useWaitForTransactionReceipt({ hash: claimData });

    const showCustomAlert = (message, explorerLink = '') => {
        setAlertMessage(message);
        setExplorerLink(explorerLink);
        setShowAlert(true);
    };

    const closeCustomAlert = () => {
        setShowAlert(false);
    };

    // Transaction Status Handlers
    const handleTransactionStart = () => {
        setWillCheck(true);
        setAlertMessage('')
        console.log('Transaction started..');
    };

    // Helper function to extract error message
    const getErrorMessage = (error) => {
        if (!error) return 'Unknown error occurred';

        // Check for user rejection message in different parts of the error object
        const errorDetails = error.details || '';
        const errorMessage = error.message || '';
        const shortMessage = error.shortMessage || '';

        // Common user rejection messages
        if (errorDetails.includes('User denied transaction signature') ||
            errorMessage.includes('User denied transaction signature') ||
            shortMessage.includes('User denied transaction signature')) {
            return 'Transaction was rejected by user';
        }

        // Check for specific error types
        if (error.name === 'ContractFunctionExecutionError') {
            // Extract the Details field from the error
            const detailsMatch = error.message.match(/Details: (.*?)(?=\n|$)/);
            if (detailsMatch) {
                return detailsMatch[1];
            }
        }

        // Gas related errors
        if (errorMessage.includes('insufficient funds')) {
            return 'Insufficient funds for gas fee';
        }

        // Network related errors
        if (errorMessage.includes('network') || errorMessage.includes('disconnected')) {
            return 'Network connection error. Please check your connection';
        }

        // Contract related errors
        if (errorMessage.includes('execution reverted')) {
            const revertMatch = errorMessage.match(/execution reverted:(.*?)(?=\n|$)/);
            if (revertMatch) {
                return revertMatch[1].trim();
            }
            return 'Transaction failed during contract execution';
        }
        
        return error.message || 'Transaction failed. Please try again';
    };
    
    const handleTransactionEnd = (text, transactionHash, error) => {

        if (error) {
            const errorMessage = getErrorMessage(error);
            showCustomAlert(errorMessage);
            setWillCheck(false);
            return;
        }

        if (text && transactionHash) {
            const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
            showCustomAlert(`${text}`, explorerLink);
        } else if (text) {
            showCustomAlert(text);
        }
        setWillCheck(false);
    };

    const getInFormat = (amount) => {
        if (amount > 0) {
          if (amount <= 1e3) {
            return amount;
          } else if (amount > 1e3 && amount <= 1e6) {
            return `${(amount / 1e3).toFixed(2)} K`;
          } else if (amount > 1e6 && amount <= 1e9) {
            return `${(amount / 1e6).toFixed(2)} M`;
          } else if (amount > 1e9) {
            return `${(amount / 1e9).toFixed(2)} B`;
          }
        } else {
          return 0;
        }
      }

    const updatePendingAmount = async () => {
        if (!isConnected && !address && !web3Stake) {
            return;
        }
        try {
            const amountMinimum = (Number(minimumAmount) / (10 ** Number(tokenDecimal))).toFixed(0);
            const amountWithdraw = (Number(withdrawFee) / (10 ** Number(tokenDecimal))).toFixed(0);
            setMinimumClaimAmount(amountMinimum);
            setWithdrawFeeAmount(amountWithdraw);
            const rewardAmount = Number(getPendingReward) / (10 ** (Number(tokenDecimal)));
            console.log('Pending reward', rewardAmount, getPendingReward, tokenDecimal);
            if (rewardAmount > 0) {
                setPendingReward(rewardAmount.toFixed(2));
            }
        } catch (error) {
            console.error("Error updating pending reward", error);
        }
    }

    const handleMaxClick = async () => {
        if (!isConnected) {
            setAmount(0);
            showCustomAlert('Connect your wallet first');
            return;
        }
        try {
            await updatePendingAmount();
            setAmount(pendingReward);
        } catch (error) {
            console.error('Error to update pendong reward', error);
        }
    };

    function shortenWalletAddress(address) {
        if (!address) return '';
        const start = address.slice(0, 6);
        const end = address.slice(-4);
        return `${start}***${end}`;
    }

    const referralURL = address ? `https://enter.impactxpro.com/referral/${address}` : '';
    const shortAddress = shortenWalletAddress(address);

    const copyLink = () => {
        if (referralURL) {
            navigator.clipboard.writeText(referralURL)
                .then(() => {
                    console.log('Referral URL copied to clipboard');
                    showCustomAlert('Referral URL copied!');
                })
                .catch((err) => {
                    console.error('Failed to copy referral URL: ', err);
                });
        } else {
            console.error('No referral URL available');
        }
    };

    const claimReward = async () => {
        console.log("Minimum amount", minimumClaimAmount, amount);
        if (pendingReward == 0) {
            showCustomAlert('No pending amount to claim!');
            return;
        }
        if (amount > minimumClaimAmount) {
            showCustomAlert(`Minimum claim amount ${minimumClaimAmount} NRG!`);
            return;
        }
        let pendingAmount = 0;
        if (pendingAmount == 0 && amount > 0) {
            pendingAmount = (amount * (10 ** (Number(tokenDecimal))));
        }
        try {
            handleTransactionStart();
            writeClaim({
                ...stakingContract,
                functionName: 'claimReward',
                args: [pendingAmount]
            });
        } catch (err) {
            console.error("Claim pending reward failed:", err);
            handleTransactionEnd(null, null, err);
            return;
        }
        setAmount(0);
        console.log('Claim Amount', pendingAmount);
    };

    useEffect(() => {
        if (!isConnected) {
            return;
        }
        const intervalId = setInterval(() => {
            updatePendingAmount();
        }, 1000);
        return () => clearInterval(intervalId);
    }, [updatePendingAmount]);

    // Effects
    useEffect(() => {
        if (willCheck) {
            if (claimError || claimReceiptError) {
                handleTransactionEnd(null, null, claimError || claimReceiptError);
            }

            if (isClaimSuccess) {
                handleTransactionEnd('Pending reward reinvest successfully!');
            }
        }
    }, [willCheck, isClaimSuccess, claimError, claimReceiptError]);

    const isLoading = isClaimPending || isClaimLoading;

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Claim Pending Reward<span className="close-modul-button" onClick={onClose}>X</span></h2>
                <div className="wallet-info">
                    <img className="user-icon" src={ManLogo}></img>
                    <div className="ref-code">
                        <span>Ref link #</span>
                        <span>{shortAddress} <button onClick={copyLink} className='copy-btn'>Copy</button></span>
                    </div>
                    <div className="tokens">
                        <span>Pending Reward</span>
                        <span>{getInFormat(pendingReward)} NRG</span>
                    </div>
                    <div className="input-group">
                        <input
                            type="number"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                        <button onClick={handleMaxClick} className="max-btn">Max</button>
                    </div>
                    <button onClick={claimReward} className="daily-deposit-btn">Claim Reward</button>
                    <p className="deposit-info">Min claim is {minimumClaimAmount} NRG. You can claim in multiples of {minimumClaimAmount} NRG. Withdraw fee {withdrawFeeAmount} NRG</p>
                </div>
                {showAlert && (
                    <CustomAlert
                        message={alertMessage}
                        explorerLink={explorerLink}
                        onClose={closeCustomAlert}
                    />
                )}
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner">
                            <img src={loadingSpinner} alt="Loading..." />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PendingRewardModul;
