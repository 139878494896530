import React from 'react';
import Milestones from './Milestones';
import ReferralNode from './ReferralNode';
import '../styles/ReferralAndMilestone.css';

const ReferralAndMilestone = () => {

    return (
        <div>
            <div>
                < ReferralNode />
            </div>
            <div>
                < Milestones />
            </div>
        </div>
    );
}

export default ReferralAndMilestone;