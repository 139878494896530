import React, { useState, useEffect } from 'react';
import dataLabAbi from '../ABI/timeABI.json';
import '../styles/AskQuickQuestion.css';

const QuestionCard = ({ question, answer, isOpen, onClick }) => {
  return (
    <div
      className={`question-card ${isOpen ? 'question-card-open' : 'question-card-closed'}`}
      onClick={onClick}
    >
      <div className="question-card-content">
        <h3 className="question-text">{question}</h3>
        <span className="question-arrow">{isOpen ? '▼' : '▲'}</span>
      </div>
      <div className="answer" dangerouslySetInnerHTML={{ __html: answer }} />
    </div>
  );
};

const AskQuickQuestion = () => {
  const [openQuestion, setOpenQuestion] = useState(null);
  const [questions, setQuestions] = useState([
    {
      "question": "What is an NFT Membership Card?",
      "answer": "An NFT Membership Card is a digital asset that represents your membership in a unique blockchain-based ecosystem. It provides exclusive benefits such as daily NRG token earnings, access to perks, and rewards depending on the type of NFT you purchase."
    },
    {
      "question": "How can I purchase an NFT Membership Card?",
      "answer": "You can purchase an NFT Membership Card by connecting your crypto wallet (MetaMask or Trust Wallet) to our platform. Once connected, select the NFT tier you want, make the payment in USDT, and mint your NFT directly on the blockchain."
    },
    {
      "question": "What are the different NFT tiers, and what do they offer?",
      "answer": "<p className='faq-3a'>We offer four NFT tiers:</p><p className='faq-3b'><span className='faq-3ba'>• $500 NFT:</span> Daily earning from 1 active NRG node.</p><p className='faq-3c'><span className='faq-3ca'>• $1000 NFT:</span> Daily earning from 2 active NRG nodes.</p><p className='faq-3d'><span className='faq-3da'>• $1500 NFT:</span> Daily earning from 3 active NRG nodes.</p><p className='faq-3e'><span className='faq-3ea'>• $2500 NFT:</span> Daily earning of 4 active NRG nodes.</p><p className='faq-3f'>Higher-tier NFTs provide greater daily rewards and exclusive benefits.</p>"
    },
    {
      "question": "Is there a membership fee?",
      "answer": "No, there is currently no monthly membership fee."
    },
    {
      "question": " How are daily earnings sustainable?",
      "answer": "The sustainability of daily earnings is ensured by the business model, where 60% of NFT sales are allocated to a dedicated rewards pool. Additional mechanisms like token buybacks and burns will further stabilize the ecosystem in the future."
    },
    {
      "question": "How do I claim my daily earnings?",
      "answer": "Daily NRG token earnings are automatically credited to your dashboard. You can monitor and claim your rewards through the membership dashboard on our platform."
    },
    {
      "question": "Can I refer others and earn rewards?",
      "answer": "Yes! Our referral program allows you to earn 15% from every NFT purchase made by someone you refer. Share your referral link to start earning."
    },
    {
      "question": "Can I resell my NFT Membership Card?",
      "answer": "Yes, your NFT Membership Card is a blockchain-based asset that can be resold or transferred on our marketplace."
    },
    {
      "question": "How is the membership paid?",
      "answer": "Membership payments are made in cryptocurrency, specifically USDT (Tether), on the POL blockchain (formerly MATIC). Ensure you have sufficient USDT and some POL for gas fees in your connected wallet to make payments."
    },
    {
      "question": "How is my data secured?",
      "answer": "All transactions are secured through blockchain technology, ensuring transparency and immutability. Your wallet and personal information are protected, and no sensitive data is stored on centralized servers."
    },
    {
      "question": "What if I lose access to my wallet?",
      "answer": "If you lose access to your wallet, you will also lose your NFT membership card and any other assets. It is important to secure the seed phrase and password of your wallet."
    },
    {
      "question": "How do I track my rewards and referrals?",
      "answer": "You can track your rewards, referral earnings, and NFT performance through our user-friendly dashboard, accessible after connecting your wallet."
    },
    {
      "question": "What benefits do I get apart from daily earnings?",
      "answer": "Depending on the NFT tier, you may gain access to exclusive perks such as: <p>• Priority access to new products or events.</p><p>• Discounts on ecosystem services.</p><p>• Special recognition within the community.</p><p>Additional benefits will be announced periodically</p>"
    },
    {
      "question": "Can I own multiple NFTs?",
      "answer": "Yes, you can own multiple NFT Membership Cards. Each NFT will provide separate daily rewards."
    },
    {
      "question": "Is the NFT Membership available globally?",
      "answer": "Yes, our NFT Membership Cards are available globally. However, ensure that cryptocurrency transactions are legally permitted in your country."
    },
    {
      "question": "What blockchain is the NFT Membership built on?",
      "answer": "The NFTs are built on the POL blockchain (formerly MATIC), ensuring low transaction fees, fast processing times, and scalability."
    },
    {
      "question": "What happens if the NFT ecosystem token value changes?",
      "answer": "The value of rewards and the ecosystem token may fluctuate based on market conditions. Our team actively manages the ecosystem to ensure stability and growth."
    },
    {
      "question": "Why do my transaction details not match the dashboard information?",
      "answer": "The values displayed in the transaction section and other areas are rounded and shown in shorthand formats like K, M, or B. For example, the transaction page might display $8, while the dashboard shows $7.5 due to rounding differences."
    }
  ]);

  const handleQuestionClick = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  return (
    <div className="ask-quick-question-container">
      <h1 className="ask-quick-question-title">Frequently asked questions</h1>
      <div className="question-list">
        {questions.map((item, index) => (
          <QuestionCard
            key={index}
            question={item.question}
            answer={item.answer}
            isOpen={openQuestion === index}
            onClick={() => handleQuestionClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default AskQuickQuestion;