import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/News.css';

const News = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://impactxtech.com/cryptopanic/', {
          params: {
            filter: 'rising',
            currencies: 'BTC,ETH',
          },
        });
        setNews(response.data.results);
        console.log('news', response.data.results)
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = (url, e) => {
    e.preventDefault();
    window.location.href = url;
  }

  const getAge = (time) => {
    const now = new Date();
    const createdAt = new Date(time);
    const diff = now - createdAt;

    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days > 0) {
      return `${days}d`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else {
      return `${minutes}m`;
    }
  };

  return (
    <div className="container">
      <div className="post-grid">
        {news.map((post, index) => {
          // Create a set to store unique currency codes
          const uniqueCurrencies = new Set(post.currencies.map(currency => currency.code));
          // Convert the set back to an array and slice to get the first 3 unique codes
          const limitedCurrencies = Array.from(uniqueCurrencies).slice(0, 3);
          
          return (
            <div key={index} className="post-card">
              <div className='title-currencies'>
                <div className='time-title'>
                  <h3 className='news-time'>{getAge(post.created_at)}</h3>
                  <h3 className="title">{post.title}</h3>
                </div>
                <div className="currencies">
                  {limitedCurrencies.map((code, idx) => (
                    <span key={idx} className="currency">{code}</span>
                  ))}
                </div>
              </div>
              <div onClick={(e) => handleClick(post.url, e)} className="source">{post.source.title}</div>
              <div className="votes">
                <span>👍 {post.votes.positive}</span>
                <span>👎 {post.votes.negative}</span>
                <span>❤️ {post.votes.liked}</span>
                <span>💬 {post.votes.comments}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default News;
