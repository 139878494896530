import React, { useEffect, useState, useContext } from 'react';
import GaugeComponent from 'react-gauge-component';
import { WalletContext } from '../WalletContext';
import { useAccount, useReadContract } from 'wagmi';
import DashboardOverview from '../assets/tutorial/dashboard-overview.mp4';
import '../styles/Milestones.css';

const Milestones = () => {
    const { address, isConnected } = useAccount();
    const [percentage, setPercentage] = useState(0);
    const [amountMilestone, setAmountMilestone] = useState(0);
    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const { contract } = useContext(WalletContext);

    const { data: milestonAmount } = useReadContract({
        ...contract,
        functionName: 'getMilestonAmount',
        args: [address],
        enabled: Boolean(address)
    });

    const VideoPopup = ({ video, onCloseVideo }) => {
        return (
            <div className="video-popup-overlay">
                <div className="video-popup">
                    <button className="popup-close-btn" onClick={onCloseVideo}>✕</button>
                    <video className='popup-video-bacground' src={video} controls autoPlay width="100%">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        );
    };

    const getPercentage = (totalAmount, amount) => {
        if (totalAmount > 0 && amount > 0) {
            return (100 / totalAmount) * amount;
        } else {
            return 0;
        }
    }

    const updateMilestones = async () => {
        if (!isConnected) {
            return;
        }

        try {
            if (milestonAmount) {
                const amount = (Number(milestonAmount) / (10 ** 6)).toFixed(0);
                const perAmount = getPercentage(1000000, amount);
                setPercentage(perAmount);
                setAmountMilestone(amount);
            }
        } catch (error) {
            console.log('Error updating mileston referral node', error);
        }
    }

    const cardData = [
        {
            title: 'LOVELACE',
            target: 20000,
            rewards: 500,
            features: {
                '$500 Reward': true,
                'NFT Certificate': true,
                'Award Ceremony': true,
                'Tech conference': false,
                '2k of tech gadgets': false,
            },
        },
        {
            title: 'TESLA',
            target: 50000,
            rewards: 1000,
            features: {
                '$1000 Reward': true,
                'NFT Certificate': true,
                'Award Ceremony': true,
                'Tech conference': false,
                '2k of tech gadgets': false,
            },
        },
        {
            title: 'TURING',
            target: 100000,
            rewards: 2000,
            features: {
                '$2000 Reward': true,
                'NFT Certificate': true,
                'Award Ceremony': true,
                'Tech conference': true,
                '2k of tech gadgets': false,
            },
        },
        {
            title: 'JOBS',
            target: 500000,
            rewards: 4000,
            features: {
                '$4000 Reward': true,
                'NFT Certificate': true,
                'Award Ceremony': true,
                'Tech conference': true,
                '2k of tech gadgets': true,
            },
        },
        {
            title: 'SATOSHI',
            target: 1000000,
            rewards: 8000,
            features: {
                '$8000 Reward': true,
                'NFT Certificate': true,
                'Award Ceremony': true,
                'Tech conference': true,
                '2k of tech gadgets': true,
            },
        },
    ];

    useEffect(() => {
        if (isConnected && milestonAmount) {
            updateMilestones();
        }
    }, [isConnected, milestonAmount]);

    return (
        <div className="referral-video-container">
            <div className='wattuto-heder-container'>
                <div><h1 className='vip-membership-header'>Volume Milestones</h1></div>
                <div><button onClick={(e) => setShowVideoPopup(true)} className='watch-tutorial'>Watch Tutorial</button></div>
            </div>
            <div className="milestone-cards-container">
                {cardData.map((card, index) => (
                    <div key={index} className="card">
                        <div className='card-title-price'>
                            <h3>{card.title}</h3>
                            <p className="price">${card.target}</p>
                        </div>
                        {card.title === 'LOVELACE' && (
                            < div className="card-video">
                                <div className='gauge-referral'>
                                    <GaugeComponent
                                        value={percentage}
                                        type="radial"
                                        labels={{
                                            tickLabels: {
                                                type: "outer",
                                                ticks: [
                                                    { value: 20 },
                                                    { value: 40 },
                                                    { value: 60 },
                                                    { value: 80 },
                                                    { value: 100 }
                                                ]
                                            }
                                        }}
                                        arc={{
                                            colorArray: ['#002e5c', '#1E90FF'],
                                            subArcs: [{ limit: 10 }, { limit: 30 }, {}, {}, {}],
                                            padding: 0.02,
                                            width: 0.3
                                        }}
                                        pointer={{
                                            elastic: true,
                                            animationDelay: 0
                                        }}
                                    />
                                    <h3 className='nrg-level-text'>{amountMilestone} USDT<span className='percent'> {percentage} %</span></h3>
                                </div>
                            </div>
                        )}
                        {card.title === 'TESLA' && (
                            < div className="card-video">
                                <div className='gauge-referral'>
                                    <GaugeComponent
                                        value={0}
                                        type="radial"
                                        labels={{
                                            tickLabels: {
                                                type: "outer",
                                                ticks: [
                                                    { value: 20 },
                                                    { value: 40 },
                                                    { value: 60 },
                                                    { value: 80 },
                                                    { value: 100 }
                                                ]
                                            }
                                        }}
                                        arc={{
                                            colorArray: ['#004800', '#32CD32'],
                                            subArcs: [{ limit: 10 }, { limit: 30 }, {}, {}, {}],
                                            padding: 0.02,
                                            width: 0.3
                                        }}
                                        pointer={{
                                            elastic: true,
                                            animationDelay: 0
                                        }}
                                    />
                                    <h3 className='nrg-level-text'>{0} USDT <span className='percent'> {0} %</span></h3>
                                </div>
                            </div>
                        )}
                        {card.title === 'TURING' && (
                            < div className="card-video">
                                <div className='gauge-referral'>
                                    <GaugeComponent
                                        value={0}
                                        type="radial"
                                        labels={{
                                            tickLabels: {
                                                type: "outer",
                                                ticks: [
                                                    { value: 20 },
                                                    { value: 40 },
                                                    { value: 60 },
                                                    { value: 80 },
                                                    { value: 100 }
                                                ]
                                            }
                                        }}
                                        arc={{
                                            colorArray: ['#5a4c00', '#FFD700'],
                                            subArcs: [{ limit: 10 }, { limit: 30 }, {}, {}, {}],
                                            padding: 0.02,
                                            width: 0.3
                                        }}
                                        pointer={{
                                            elastic: true,
                                            animationDelay: 0
                                        }}
                                    />
                                    <h3 className='nrg-level-text'>{0} USDT <span className='percent'> {0} %</span></h3>
                                </div>
                            </div>
                        )}
                        {card.title === 'JOBS' && (
                            < div className="card-video">
                                <div className='gauge-referral'>
                                    <GaugeComponent
                                        value={0}
                                        type="radial"
                                        labels={{
                                            tickLabels: {
                                                type: "outer",
                                                ticks: [
                                                    { value: 20 },
                                                    { value: 40 },
                                                    { value: 60 },
                                                    { value: 80 },
                                                    { value: 100 }
                                                ]
                                            }
                                        }}
                                        arc={{
                                            colorArray: ['#4f2c00', '#FF8C00'],
                                            subArcs: [{ limit: 10 }, { limit: 30 }, {}, {}, {}],
                                            padding: 0.02,
                                            width: 0.3
                                        }}
                                        pointer={{
                                            elastic: true,
                                            animationDelay: 0
                                        }}
                                    />
                                    <h3 className='nrg-level-text'>{0} USDT <span className='percent'> {0} %</span></h3>
                                </div>
                            </div>
                        )}
                        {card.title === 'SATOSHI' && (
                            < div className="card-video">
                                <div className='gauge-referral'>
                                    <GaugeComponent
                                        value={0}
                                        type="radial"
                                        labels={{
                                            tickLabels: {
                                                type: "outer",
                                                ticks: [
                                                    { value: 20 },
                                                    { value: 40 },
                                                    { value: 60 },
                                                    { value: 80 },
                                                    { value: 100 }
                                                ]
                                            }
                                        }}
                                        arc={{
                                            colorArray: ['#4e1500', '#FF4500'],
                                            subArcs: [{ limit: 10 }, { limit: 30 }, {}, {}, {}],
                                            padding: 0.02,
                                            width: 0.3
                                        }}
                                        pointer={{
                                            elastic: true,
                                            animationDelay: 0
                                        }}
                                    />
                                    <h3 className='nrg-level-text'>{0} USDT <span className='percent'> {0} %</span></h3>
                                </div>
                            </div>
                        )}
                        <p className="reward-amount">${card.rewards}</p>
                        <ul className="features">
                            {Object.entries(card.features).map(([feature, included], i) => (
                                <li key={i} className={included ? 'active-feature' : 'inactive-feature'}>
                                    {feature}
                                    <span className={included ? 'checkmark' : 'cross'}>
                                        {included ? '✓' : '✗'}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            {showVideoPopup && (
                <VideoPopup
                    video={DashboardOverview}
                    onCloseVideo={() => setShowVideoPopup(false)}
                />
            )}
        </div>
    );
}

export default Milestones;