import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useAccount, useReadContract, useReadContracts } from 'wagmi';
import { WalletContext } from '../WalletContext';
import FanPass from '../assets/membervideos/fanpass.mp4';
import BitcoinExplorer from '../assets/membervideos/bitcoinexplore.mp4';
import BlockchainPro from '../assets/membervideos/blockchainpro.mp4';
import MetaverseMaster from '../assets/membervideos/metaversemaster.mp4';
import AiUltimatePro from '../assets/membervideos/aiultimate.mp4';
import '../styles/YourMembership.css';

const YourMembership = () => {
    const { address, isConnected } = useAccount();
    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const { nftContract } = useContext(WalletContext);

    const initialCardData = [
        {
            title: 'FAN PASS',
            video: FanPass,
            price: 50,
            features: [
                'Entry-level NRG Membership',
                'Access to Basic ImpactX Dapps',
                'Earn NRG tokens to upgrade',
                'Starter Networking Events',
            ]
        },
        {
            title: 'BITCOIN EXPLORER',
            video: BitcoinExplorer,
            price: 500,
            features: [
                '1 NRG Active Node',
                '$100 of NRG tokens',
                'Access to ImpactX Dapps',
                'Access to Networking Events',
                'Access to Academy',
            ]
        },
        {
            title: 'BLOCKCHAIN PRO',
            video: BlockchainPro,
            price: 1000,
            features: [
                '2 NRG Active Node',
                '$200 of NRG tokens',
                'Access to ImpactX Dapps',
                'Access to Networking Events',
                'Access to Academy',
            ]
        },
        {
            title: 'METAVERSE MASTER',
            video: MetaverseMaster,
            price: 1500,
            features: [
                '3 NRG Active Node',
                '$300 of NRG tokens',
                'Access to ImpactX Dapps',
                'Access to Networking Events',
                'Access to Academy',
            ]
        },
        {
            title: 'AI ULTIMATE PRO',
            video: AiUltimatePro,
            price: 2500,
            features: [
                '4 NRG Active Node',
                '$400 of NRG tokens',
                'Access to ImpactX Dapps',
                'Access to Networking Events',
                'Access to Academy',
            ]
        },
    ];

    // Read token decimals
    const { data: getOwnedTokens } = useReadContract({
        ...nftContract,
        functionName: 'getOwnedTokens',
        args: [address],
        enabled: Boolean(address)
    });

    const { data: characterOf } = useReadContracts({
        contracts: getOwnedTokens?.map((tokenId) => ({
            ...nftContract,
            functionName: 'characterOf',
            args: [tokenId]
        })) ?? [],
        enabled: !!getOwnedTokens?.length
    });

    const getFanPassBalance = () => {
        let balance = 0;
        if (isConnected && characterOf) {
            for (let i = 0; i < characterOf.length; i++) {
                if (Number(characterOf[i].result) == 1) {
                    balance += 1;
                }
                console.log('Your nft', Number(characterOf[i].result));
            }
        }
        return balance;
    };

    const getBitcoinExplorerBalance = () => {
        let balance = 0;
        if (isConnected && characterOf) {
            for (let i = 0; i < characterOf.length; i++) {
                if (Number(characterOf[i].result) == 2) {
                    balance += 1;
                }
            }
        }
        return balance;
    };

    const getBlockchainProBalance = () => {
        let balance = 0;
        if (isConnected && characterOf) {
            for (let i = 0; i < characterOf.length; i++) {
                if (Number(characterOf[i].result) == 3) {
                    balance += 1;
                }
            }
        }
        return balance;
    };

    const getMetaverseMasterBalance = () => {
        let balance = 0;
        if (isConnected && characterOf) {
            for (let i = 0; i < characterOf.length; i++) {
                if (Number(characterOf[i].result) == 4) {
                    balance += 1;
                }
            }
        }
        return balance;
    };

    const getAiUltimateProBalance = () => {
        let balance = 0;
        if (isConnected && characterOf) {
            for (let i = 0; i < characterOf.length; i++) {
                if (Number(characterOf[i].result) == 5) {
                    balance += 1;
                }
            }
        }
        return balance;
    };

    const VideoPopup = ({ onCloseVideo }) => {
        return (
            <div className="video-popup-overlay">
                <div className="video-popup">
                    <button className="popup-close-btn" onClick={onCloseVideo}>✕</button>
                    <div className='video-popup-container'>
                        <iframe className='popup-video popup-video-bacground' src="https://player.vimeo.com/video/1042861687?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Yourmembershipcardvideo">
                        </iframe>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="nft-listing-container">
            <button onClick={(e) => setShowVideoPopup(true)} className='watch-tutorial'>Watch Tutorial</button>
            <div className="nft-grid">
                {getFanPassBalance() > 0 && (
                    <div className="nft-card">
                        <div className='card-container'>
                            <div className="nft-header">
                                <h3 className="nft-title">{initialCardData[0].title}</h3>
                                <video className='nft-image' src={initialCardData[0].video} controls autoPlay loop width="100%" height="100%" />
                            </div>
                            <div className="nft-content">
                                <p className="nft-feature">Features:</p>
                                <p className="nft-info"><span>${initialCardData[0].features[0]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[0].features[1]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[0].features[2]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[0].features[3]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-total"><span>Total NFTs: </span> <span className='fan-balance'>{getFanPassBalance()}</span></p>
                                <p className="nft-value"><span>NFTs Value: </span> <span className='fan-value'>${getFanPassBalance() * initialCardData[0].price}</span></p>
                            </div>
                        </div>
                    </div>
                )}
                {getBitcoinExplorerBalance() > 0 && (
                    <div className="nft-card">
                        <div className='card-container'>
                            <div className="nft-header">
                                <h3 className="nft-title">{initialCardData[1].title}</h3>
                                <video className='nft-image' src={initialCardData[1].video} controls autoPlay loop width="100%" height="100%" />
                            </div>
                            <div className="nft-content">
                                <p className="nft-feature">Features:</p>
                                <p className="nft-info"><span>${initialCardData[1].features[0]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[1].features[1]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[1].features[2]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[1].features[3]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[1].features[4]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-total"><span>Total NFTs: </span> <span className='bte-balance'>{getBitcoinExplorerBalance()}</span></p>
                                <p className="nft-value"><span>NFTs Value: </span> <span className='bte-value'>${getBitcoinExplorerBalance() * initialCardData[1].price}</span></p>
                            </div>
                        </div>
                    </div>
                )}
                {getBlockchainProBalance() > 0 && (
                    <div className="nft-card">
                        <div className='card-container'>
                            <div className="nft-header">
                                <h3 className="nft-title">{initialCardData[2].title}</h3>
                                <video className='nft-image' src={initialCardData[2].video} controls autoPlay loop width="100%" height="100%" />
                            </div>
                            <div className="nft-content">
                                <p className="nft-feature">Features:</p>
                                <p className="nft-info"><span>${initialCardData[2].features[0]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[2].features[1]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[2].features[2]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[2].features[3]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[2].features[4]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-total"><span>Total NFTs: </span> <span className='blp-balance'>{getBlockchainProBalance()}</span></p>
                                <p className="nft-value"><span>NFTs Value: </span> <span className='blp-value'>${getBlockchainProBalance() * initialCardData[2].price}</span></p>
                            </div>
                        </div>
                    </div>
                )}
                {getMetaverseMasterBalance() > 0 && (
                    <div className="nft-card">
                        <div className='card-container'>
                            <div className="nft-header">
                                <h3 className="nft-title">{initialCardData[3].title}</h3>
                                <video className='nft-image' src={initialCardData[3].video} controls autoPlay loop width="100%" height="100%" />
                            </div>
                            <div className="nft-content">
                                <p className="nft-feature">Features:</p>
                                <p className="nft-info"><span>${initialCardData[3].features[0]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[3].features[1]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[3].features[2]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[3].features[3]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[3].features[4]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-total"><span>Total NFTs: </span> <span className='mvm-balance'>{getMetaverseMasterBalance()}</span></p>
                                <p className="nft-value"><span>NFTs Value: </span> <span className='mvm-value'>${getMetaverseMasterBalance() * initialCardData[3].price}</span></p>
                            </div>
                        </div>
                    </div>
                )}
                {getAiUltimateProBalance() > 0 && (
                    <div className="nft-card">
                        <div className='card-container'>
                            <div className="nft-header">
                                <h3 className="nft-title">{initialCardData[4].title}</h3>
                                <video className='nft-image' src={initialCardData[4].video} controls autoPlay loop width="100%" height="100%" />
                            </div>
                            <div className="nft-content">
                                <p className="nft-feature">Features:</p>
                                <p className="nft-info"><span>${initialCardData[4].features[0]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[4].features[1]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[4].features[2]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[4].features[3]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-info"><span>${initialCardData[4].features[4]}</span> <span className='right-tick'>✓</span></p>
                                <p className="nft-total"><span>Total NFTs: </span> <span className='ail-balance'>{getAiUltimateProBalance()}</span></p>
                                <p className="nft-value"><span>NFTs Value: </span> <span className='ail-value'>${getAiUltimateProBalance() * initialCardData[4].price}</span></p>
                            </div>
                        </div>
                    </div>
                )}
                {showVideoPopup && (
                    <VideoPopup
                        onCloseVideo={() => setShowVideoPopup(false)}
                    />
                )}
            </div>
        </div>
    );
}

export default YourMembership;