import React, { useState, useContext, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import PendingRewardModul from './PendingRewardModul';
import WithdrawCommition from './WithdrawCommition';
import ManLogo from '../assets/MaleLogo.png';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import '../styles/DailyEarningModul.css';

const DailyEarningModul = ({ isOpen, onClose }) => {
    const { address, isConnected } = useAccount();
    const { pendingReward, stakingContract, usdtDecimal } = useContext(WalletContext);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [explorerLink, setExplorerLink] = useState('');
    const [isPendingRewardOpen, setIsPendingRewardOpen] = useState(false);
    const [isErningModulOpen, setIsErningModulOpen] = useState(false);
    const [commition, setCommition] = useState(0);
    const [commitionWithOutK, setCommitionWithOutK] = useState(0);
    const [willCheck, setWillCheck] = useState(false);

    // Read commission using wagmi
    const { data: userCommition } = useReadContract({
        ...stakingContract,
        functionName: 'commition',
        args: [address],
        enabled: Boolean(address)
    });

    // Write contract setup using wagmi
    const { 
        writeContract: writeReinvestPendingReward, 
        data: reinvestPendingRewardData, 
        error: reinvestPendingRewardError, 
        isPending: isReinvestPendingRewardPending 
    } = useWriteContract();

    const {
        writeContract: writeReinvestCommition,
        data: reinvestCommitionData,
        error: reinvestCommitionError,
        isPending: isReinvestCommitionPending
    } = useWriteContract();

    // Transaction Receipts
    const {
        isLoading: isReinvestPendingRewardLoading,
        isSuccess: isReinvestPendingRewardSuccess,
        error: reinvestPendingRewardReceiptError,
    } = useWaitForTransactionReceipt({ hash: reinvestPendingRewardData });
    
    const {
        isLoading: isReinvestCommitionLoading,
        isSuccess: isReinvestCommitionSuccess,
        error: reinvestCommitionReceiptError,
    } = useWaitForTransactionReceipt({ hash: reinvestCommitionData });

    const showCustomAlert = (message, explorerLink = '') => {
        setAlertMessage(message);
        setExplorerLink(explorerLink);
        setShowAlert(true);
    };

    const closeCustomAlert = () => {
        setShowAlert(false);
    };

    // Transaction Status Handlers
    const handleTransactionStart = () => {
        setWillCheck(true);
        setAlertMessage('')
        console.log('Transaction started..');
    };

    // Helper function to extract error message
    const getErrorMessage = (error) => {
        if (!error) return 'Unknown error occurred';
        
        // Check for user rejection message in different parts of the error object
        const errorDetails = error.details || '';
        const errorMessage = error.message || '';
        const shortMessage = error.shortMessage || '';
        
        // Common user rejection messages
        if (errorDetails.includes('User denied transaction signature') ||
            errorMessage.includes('User denied transaction signature') ||
            shortMessage.includes('User denied transaction signature')) {
            return 'Transaction was rejected by user';
        }

        // Check for specific error types
        if (error.name === 'ContractFunctionExecutionError') {
            // Extract the Details field from the error
            const detailsMatch = error.message.match(/Details: (.*?)(?=\n|$)/);
            if (detailsMatch) {
                return detailsMatch[1];
            }
        }

        // Gas related errors
        if (errorMessage.includes('insufficient funds')) {
            return 'Insufficient funds for gas fee';
        }

        // Network related errors
        if (errorMessage.includes('network') || errorMessage.includes('disconnected')) {
            return 'Network connection error. Please check your connection';
        }

        // Contract related errors
        if (errorMessage.includes('execution reverted')) {
            const revertMatch = errorMessage.match(/execution reverted:(.*?)(?=\n|$)/);
            if (revertMatch) {
                return revertMatch[1].trim();
            }
            return 'Transaction failed during contract execution';
        }

        // If no specific error is found, return the original message or a default
        return error.message || 'Transaction failed. Please try again';
    };

    // Update the handleTransactionEnd to use the new error handling
    const handleTransactionEnd = (text, transactionHash, error) => {
        
        if (error) {
            const errorMessage = getErrorMessage(error);
            showCustomAlert(errorMessage);
            setWillCheck(false);
            return;
        }

        if (text && transactionHash) {
            const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
            showCustomAlert(`${text}`, explorerLink);
        } else if (text) {
            showCustomAlert(text);
        }
        setWillCheck(false);
    };

    const updateCommition = async () => {
        if (!isConnected || !userCommition) {
            return;
        }
        try {
            const commitionAmount = Number(userCommition) / (10 ** usdtDecimal);
            setCommitionWithOutK(commitionAmount);
            if (commitionAmount > 0 && commitionAmount < 1e3) {
                setCommition(commitionAmount.toFixed(2));
            } else if (commitionAmount >= 1e3 && commitionAmount < 1e6) {
                const commitionInKilo = commitionAmount / 1e3;
                setCommition(`${commitionInKilo.toFixed(2)} K`);
            } else if (commitionAmount >= 1e6 && commitionAmount < 1e9) {
                const commitionInKilo = commitionAmount / 1e6;
                setCommition(`${commitionInKilo.toFixed(2)} M`);
            } else if (commitionAmount >= 1e9) {
                const commitionInKilo = commitionAmount / 1e9;
                setCommition(`${commitionInKilo.toFixed(2)} B`);
            } else {
                setCommition(0);
            }
            console.log('Commition updated.', commitionAmount);
        } catch (error) {
            console.error('Error updating commition', error);
        }
    };

    function shortenWalletAddress(address) {
        if (!address) return '';
        const start = address.slice(0, 6);
        const end = address.slice(-4);
        return `${start}***${end}`;
    }

    const referralURL = address ? `https://enter.impactxpro.com/referral/${address}` : '';
    const shortAddress = shortenWalletAddress(address);

    const copyLink = () => {
        if (referralURL) {
            navigator.clipboard.writeText(referralURL)
                .then(() => {
                    console.log('Referral URL copied to clipboard');
                    showCustomAlert('Referral URL copied!');
                })
                .catch((err) => {
                    console.error('Failed to copy referral URL: ', err);
                });
        } else {
            console.error('No referral URL available');
        }
    };

    const reinvestPendingReward = async () => {
        try {
            handleTransactionStart();
            writeReinvestPendingReward({
                ...stakingContract,
                functionName: 'reinvestPendingReward'
            });
        } catch (error) {
            console.error('Error to reinvest pending reward', error);
            handleTransactionEnd('Error reinvest pending reward');
        }
    };

    const reinvestReferralReward = async () => {
        if (commition === 0) {
            console.log('No commition reward to reinvest.');
            showCustomAlert('No commition reward to reinvest.');
            return;
        }
        try {
            handleTransactionStart();
            writeReinvestCommition({
                ...stakingContract,
                functionName: 'reinvestCommition'
            });
        } catch (error) {
            console.error('Error to reinvest commition reward', error);
            handleTransactionEnd('Error to reinvest commition reward');
        }
    };

    const claimPendingReward = () => {
        if (pendingReward < 16670) {
            showCustomAlert('Minimum amount to claim is 16670 NRG!');
            return;
        } else {
            setIsPendingRewardOpen(true);
        }
    };

    const withdrawCommitionReward = () => {
        if (commitionWithOutK < 16670) {
            showCustomAlert('Minimum amount to withdraw is 16670 NRG!');
            return;
        } else {
            setIsErningModulOpen(true);
        }
    };

    useEffect(() => {
        if (!isConnected) {
            return;
        }
        updateCommition();
        const intervalId = setInterval(updateCommition, 1000);
        return () => clearInterval(intervalId);
    }, [isConnected, userCommition]);
    
    // Effects
    useEffect(() => {
        if (willCheck) {
            if (reinvestPendingRewardError || reinvestPendingRewardReceiptError) {
                handleTransactionEnd(null, null, reinvestPendingRewardError || reinvestPendingRewardReceiptError);
            }
            
            if (isReinvestPendingRewardSuccess) {
                handleTransactionEnd('Pending reward reinvest successfully!');
            }
        }
    }, [willCheck, isReinvestPendingRewardSuccess, reinvestPendingRewardError, reinvestPendingRewardReceiptError]);

    useEffect(() => {
        if (willCheck) {
            if (reinvestCommitionError || reinvestCommitionReceiptError) {
                handleTransactionEnd(null, null, reinvestCommitionError || reinvestCommitionReceiptError);
            }
            
            if (isReinvestCommitionSuccess) {
                handleTransactionEnd('Commition reinvest successfully!');
            }
        }
    }, [willCheck, reinvestCommitionError, reinvestCommitionReceiptError, isReinvestCommitionSuccess]);

    if (!isOpen) return null;

    const isLoading = isReinvestPendingRewardPending || isReinvestPendingRewardLoading || isReinvestCommitionPending || isReinvestCommitionLoading;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Pending Rewards<span className="close-modul-button" onClick={onClose}>X</span></h2>
                <div className="wallet-info">
                    <img className="user-icon" src={ManLogo} alt="User Icon" />
                    <div className="ref-code">
                        <span className='referral-link'>Ref link #</span>
                        <span>{shortAddress} <button onClick={copyLink} className='copy-btn'>Copy</button></span>
                    </div>
                    <div className="tokens">
                        <div className='pending-reward'>Pending Rewards: <span className='reward'>{pendingReward}</span> NRG</div>
                        <div className='withdraw-claim-btn'>
                            <button 
                                onClick={claimPendingReward} 
                                className="claim-btn"
                                disabled={isLoading}
                            >
                                Claim
                            </button>
                            <button 
                                onClick={reinvestPendingReward} 
                                className="reinvest-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Reinvesting...' : 'Reinvest'}
                            </button>
                        </div>
                    </div>
                    <div className="tokens">
                        <div className='pending-reward'>Referral: <span className='reward'>{commition}</span> NRG</div>
                        <div className='withdraw-claim-btn'>
                            <button 
                                onClick={withdrawCommitionReward} 
                                className="claim-btn"
                                disabled={isReinvestCommitionLoading}
                            >
                                Withdraw
                            </button>
                            <button 
                                onClick={reinvestReferralReward} 
                                className="reinvest-btn"
                                disabled={isReinvestCommitionLoading}
                            >
                                {isLoading ? 'Reinvesting...' : 'Reinvest'}
                            </button>
                        </div>
                    </div>
                    <p className="minimum-withdraw">Min withdraw is 16670 NRG. You can withdraw in multiples of 16670 NRG</p>
                </div>
                <PendingRewardModul
                    isOpen={isPendingRewardOpen}
                    onClose={() => setIsPendingRewardOpen(false)}
                />
                <WithdrawCommition
                    isOpen={isErningModulOpen}
                    onClose={() => setIsErningModulOpen(false)}
                />
                {showAlert && (
                    <CustomAlert
                        message={alertMessage}
                        explorerLink={explorerLink}
                        onClose={closeCustomAlert}
                    />
                )}
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner">
                            <img src={loadingSpinner} alt="Loading..." />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DailyEarningModul;