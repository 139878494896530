import React, { useContext, useEffect, useState } from 'react';
import Marquee from "react-fast-marquee";
import { WalletContext } from '../WalletContext';
import { useAccount, useReadContract, useReadContracts } from 'wagmi';
import ads1 from '../assets/ads/ads1.png';
import ads2 from '../assets/ads/ads2.png';
import '../styles/BennerAds.css';

const BennerAds = () => {
    const { isConnected } = useAccount();
    const { stakingContract } = useContext(WalletContext);
    const [ads, setAds] = useState([{
        logo: ads1,
        url: 'https://a.media-amazon.com/images/I/617l83eY1rL._SX679_.jpg'
    }, {
        logo: ads2,
        url: 'https://a.media-amazon.com/images/I/617l83eY1rL._SX679_.jpg'
    }, {
        logo: ads1,
        url: 'https://a.media-amazon.com/images/I/617l83eY1rL._SX679_.jpg'
    }, {
        logo: ads2,
        url: 'https://a.media-amazon.com/images/I/617l83eY1rL._SX679_.jpg'
    }]);
    const [nonces, setNonces] = useState([]);

    const { data: adsNonce } = useReadContract({
        ...stakingContract,
        functionName: 'adsNonce'
    });

    const { data: timeNow } = useReadContract({
        ...stakingContract,
        functionName: 'getCurrentTime'
    });
    
    const { data: adsData } = useReadContracts({
        contracts: nonces?.map((nonce) => ({
            ...stakingContract,
            functionName: 'getAdsData',
            args: [nonce]
        })) ?? [],
        enabled: !!nonces?.length
    });
    
    useEffect(() => {
        if (isConnected && adsNonce && nonces.length === 0) {
            const totalNonce = [];
            for (let i = 1; i <= Number(adsNonce); i++) {
                totalNonce.push(i);
            }
            setNonces(totalNonce);
        }
    }, [isConnected, adsNonce]);
    
    useEffect(() => {
        const processAdsData = async () => {
            // console.log('Total nonce:', adsData[0]);
            if (!adsData || !timeNow) return;

            try {
                const currentTime = Number(timeNow);
                const newAds = adsData
                    .filter(ad => ad?.result)
                    .filter(ad => {
                        const startAt = Number(ad.result.startAt);
                        const endAt = Number(ad.result.endAt);
                        return startAt <= currentTime &&
                            endAt >= currentTime &&
                            ad.result.isRunning;
                    })
                    .map(ad => ({
                        logo: ad.result.imageUrl,
                        url: ad.result.redirectLink
                    }));
                setAds(newAds);
            } catch (error) {
                console.error('Error processing ads data:', error);
            }
        };

        processAdsData();
    }, [adsData, timeNow]);

    return (
        <Marquee pauseOnHover={true} speed={45} gradientWidth={0}>
            {ads.map((ad, index) => (
                <div key={index} className="images-item">
                    <a href={ad.url} target="_blank" rel="noopener noreferrer">
                        <img src={ad.logo} className="images-logo" alt={`Banner ${index + 1}`} />
                    </a>
                </div>
            ))}
        </Marquee>
    );
};

export default BennerAds;